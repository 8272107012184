var baseUrl = "/"
var extdata1 =
{
    tableName: 'extdata1',
    header: 'Tarihçe',
    columns:
    {
        id: {
            type: 'text',
            label: 'ID',
            edit: '01X',
        },
    },
    attributes:
    {

    },
    extdatainfo: {
        id: 'id',
        ident: 'formid',
        formField1: 'form',
        formFieldValue1: 'groupdefinition',
        formField2: '',
        formFieldValue2: '',
    },
    data: [

    ]
}

var InitialStateTemplate = {
    groups: [
    ],
    tabs:
        [
        ],
    Columns: {},
    attributes: {},
    formdata: {},
    dynamicFormAttributes: {},
    dynamicFormData: {},
    dynamicFormColumns: {},
    formdataServer: {},
    TabAttributes: {},
    userInfo: { AdSoyad: ' ', Gorev: '', Login: '0' },
    userMenu: [],
    actions: [],
    view:
    {

        data: [],
        gridInfo: { GridTitle: '' },
        columns: {},
        searchkeys: [],
        errorMessage: '',
        debug: '',
        loading: false,

    },
    form: '',
    formid: 0,
    table: 'a',
    viewName: '',
    viewfilter: '',
    show: 'view',
    files: [],
    extdata: [],
    ignoreActions: false,
    extdataServer: [],
    messages: {
        errorMessage: '',
        infoMessage: '',
        successMessage: '',
    },
    axios:
    {
        requestCount: 0,
    },
    timers:
    {
        timerId: 0,
    },
    UserLoggedIn: false,
    LoadingFormData: false,
    LoadingFormDataExpiresAt: Date.now(),
}

import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import axios from 'axios'
import moment from 'moment';
import Utilities from '@/Utilities.js'
import router from './router'
Vue.use(Vuex);




export default new Vuex.Store(
    {
        state: InitialStateTemplate,
        plugins: [createPersistedState()],
        mutations:
        {
            SetField(state, val) {
                //payload
                //  column ( colum definition) => fieldname
                //  value ( updated value)
                //  tableName (formdata = form_data)
                //  id table içinde id
                Utilities.log("setfield in:", val);
                var fieldName = val.fieldName || "";
                if (fieldName == "") return;
                var fieldValue = val.fieldValue;
                var tableName = val.tableName || "";
                var id = val.id

                if (tableName == "" || tableName == 'formdata') {
                    state.formdata[fieldName] = fieldValue;
                }
                else if (tableName == 'dynamicFormData') {
                    state.dynamicFormData[fieldName] = fieldValue;
                }
                else {
                    var extdata = null;
                    for (var i = 0; i < state.extdata.length; i++) {
                        if (state.extdata[i].tableName == tableName) {
                            extdata = state.extdata[i];
                        }
                    }
                    if (extdata) {
                        var items = extdata.data;
                        //Utilities.log("Items:" + JSON.stringify(items));

                        var item = items.find(function (ext) {
                            return ext.id == id
                        });

                        if (item) {
                            item[fieldName] = fieldValue.toString().trim();
                            //Utilities.log("Updated Extdata:" + JSON.stringify(item));
                        }
                    }
                    else
                        Utilities.log("Extdata bulunamadı:" + JSON.stringify(val))
                }
            },
            SetFields(state, payload) {
                if (payload.form == "dynamicFormData") {
                    for (var key in payload) {
                        state.dynamicFormData[key] = payload[key];
                    }
                }
                else {
                    for (var key in payload) {
                        state.formdata[key] = payload[key];
                    }
                }
            },
            SetHostUrl(state, payload) {
                if (payload == undefined) {
                    var hostUrl =
                        window.location.protocol +
                        '//' +
                        window.location.hostname +
                        (window.location.port ? ':' + window.location.port : '') + "/";
                    // + '/akforms/';
                    payload = hostUrl;
                }
                state.hostUrl = payload;
            },
            UpdateTimeNow(state) {
                var timeNow = moment().format("DD/MM/YYYY HH:mm:ss");
                state.timeNow = timeNow;
            },
            SetFieldAttributes(state, payload) {
                Utilities.log("in SetFieldAttributes table:" + payload.tableName
                    + " field:" + payload.fieldName + " attribute:" + payload.attributeName);
                //Utilities.log("Value:" + JSON.stringify(payload.value));
                // var payload = {
                //     tableName: "formdata", // Form datasi ile tableName ='formdata'
                //     fieldName: "ad",
                //     attributeName: "items",
                //     id: 0,
                //     value: TransformListToItems(itemList,['ad','soyad','sicil']),
                //   };
                var tableName = payload.tableName;
                var fieldName = payload.fieldName;
                Utilities.log("table:", tableName, " field:" + fieldName, " items:", payload.value)
                if (tableName === 'formdata') {
                    //Form field lerinin attibute larını güncelle;
                    if (typeof state.attributes[fieldName] == "undefined")
                        state.attributes[fieldName] = {};
                    if (typeof state.attributes[fieldName][payload.attributeName] == "undefined")
                        state.attributes[fieldName][payload.attributeName] = {};
                    // state.attributes[fieldName][payload.attributeName] = payload.value;
                    Vue.set(state.attributes[fieldName], payload.attributeName, payload.value)
                    Utilities.log("State:", state)
                }
                else if (tableName === 'dynamicFormData')
                {
                    if (typeof state.dynamicFormAttributes[fieldName] == "undefined")
                        state.dynamicFormAttributes[fieldName] = {};
                    if (typeof state.dynamicFormAttributes[fieldName][payload.attributeName] == "undefined")
                        state.dynamicFormAttributes[fieldName][payload.attributeName] = {};

                    Vue.set(state.dynamicFormAttributes[fieldName], payload.attributeName, payload.value);
                }
                else {
                    var indx = state.extdata.findIndex((t) => { return t.tableName == tableName });
                    if (indx != -1) {
                        Utilities.log("SetFieldAttributes on ext table", payload);
                        if (typeof state.extdata[indx].attributes == "undefined")
                            state.extdata[indx].attributes = {};
                        if (typeof state.extdata[indx].attributes[payload.id] == "undefined")
                            state.extdata[indx].attributes[payload.id] = {};
                        if (typeof state.extdata[indx].attributes[payload.id][fieldName] == "undefined")
                            state.extdata[indx].attributes[payload.id][fieldName] = {};

                        Utilities.log(" Parametreler", indx, payload.id, fieldName);
                        var attributes = state.extdata[indx].attributes;
                        attributes[payload.id][fieldName][payload.attributeName] = payload.value;
                        var newAttributes = JSON.parse(JSON.stringify(attributes));
                        Vue.set(state.extdata[indx], "attributes", newAttributes);
                        //Vue.set(state.extdata[indx].attributes[payload.id][fieldName], payload.attributeName, payload.value);
                        Utilities.log("Extdata After set", state.extdata);
                    }
                    else {
                        Utilities.log("SetFieldAttributes Table not found:", payload);
                    }
                }
            },

            SetExtDataColumnAttributes(state, payload) {
                Utilities.log("in SetColumnAttributes table:" + payload.tableName
                    + " field:" + payload.fieldName + " attribute:" + payload.attributeName);

                var tableName = payload.tableName;
                var fieldName = payload.fieldName;
                Utilities.log("table:", tableName, " field:" + fieldName, " items:", payload.value)

                var indx = state.extdata.findIndex((t) => { return t.tableName == tableName });
                if (indx != -1) {
                    Utilities.log("SetColumnAttributes on ext table", payload);
                    if (typeof state.extdata[indx].columns == "undefined")
                        state.extdata[indx].columns = {};
                    if (typeof state.extdata[indx].columns[fieldName] == "undefined")
                        state.extdata[indx].columns[fieldName] = {};

                    Utilities.log(" Parametreler", indx, payload);

                    var columns = state.extdata[indx].columns;
                    columns[fieldName][payload.attributeName] = payload.value;

                    var newColumns = JSON.parse(JSON.stringify(columns));
                    Vue.set(state.extdata[indx], "columns", newColumns);
                    Utilities.log("Extdata After set", state.extdata[indx]);
                }
                else {
                    Utilities.log("SetColumnAttributes Table not found:", payload);
                }

            },

            UpdateExtData(state, payload) {
                //Utilities.log("Update payload:" + JSON.stringify(payload));
                Utilities.log("Update UpdateExtData  payload table :" + payload.tableName);
                // Utilities.log("Update state:" + JSON.stringify(payload.state));
                // Utilities.log("Update state.extdata:" + JSON.stringify(state.extdata));
                var extdata = null;
                for (var i = 0; i < state.extdata.length; i++) {
                    //Utilities.log("Checking table:" + state.extdata[i].tableName)
                    if (state.extdata[i].tableName == payload.tableName) {
                        extdata = state.extdata[i];
                        break;
                    }
                }
                if (extdata) {
                    var rowid = payload.rowdata.rowid;
                    var items = extdata.data;
                    var item = items.find(function (ext) {
                        return ext.rowid == rowid
                    });
                    if (item) {
                        // item üzerinde ilgili alanları güncelle
                        var columnDefinition = payload.columnValue.column;
                        var outputs = columnDefinition.outputs;
                        var columnName = payload.columnName;
                        var fieldValue = payload.columnValue.fieldValue;
                        item[columnName] = fieldValue;

                        if (outputs != undefined && typeof outputs == "object") {

                            for (var field in outputs) {
                                try {
                                    item[outputs[field]] = payload.columnValue.selectedObject.value[field];
                                } catch (e) {
                                    Utilities.log(" Field yok", field);
                                }
                            }
                        }
                    }
                    Utilities.log("New Extdata:", items);
                }
                else
                    Utilities.log("Extdata bulunamadı:" + payload.extdata)
            },
            UpdateExtDataField(state, payload) {
                // Payload hold columnName, fieldValue and rowdata.rowid

                Utilities.log("Update UpdateExtData  payload table :" + payload.tableName);
                // Utilities.log("Update state:" + JSON.stringify(payload.state));
                // Utilities.log("Update state.extdata:" + JSON.stringify(state.extdata));
                var extdata = null;
                for (var i = 0; i < state.extdata.length; i++) {
                    //Utilities.log("Checking table:" + state.extdata[i].tableName)
                    if (state.extdata[i].tableName == payload.tableName) {
                        extdata = state.extdata[i];
                        break;
                    }
                }
                if (extdata) {
                    var rowid = payload.rowid;
                    var items = extdata.data;
                    var item = items.find(function (ext) {
                        return ext.rowid == rowid
                    });
                    if (item) {
                        // item üzerinde ilgili alanları güncelle
                        var columnName = payload.columnName;
                        var fieldValue = payload.fieldValue;
                        item[columnName] = fieldValue;
                    }
                    Utilities.log("New Extdata:", items);
                }
                else
                    Utilities.log("Extdata bulunamadı:" + payload.extdata)

            },

            ClearForm(state, dynamic) {

                if (dynamic == true) {
                    // Clear form data;
                    var fdata = {};
                    for (var key in state.dynamicFormData) {
                        fdata[key] = null;
                    }
                    state.dynamicFormData = fdata;
                    state.dynamicFormData.state = "0";
                    state.dynamicFormData.id = 0;

                    var columns = state.dynamicFormColumns;
                    var attr = {};
                    for (key in columns) {
                        if (columns.hasOwnProperty(key)) {
                            attr[key] = {
                                items: [],
                                readonly: false,
                                hidden: false,
                                hide: false,
                                error: false,
                                disabled: false,
                            };
                        }
                    }

                    state.dynamicFormAttributes = attr;
                }
                else {
                    // Clear form data;
                    var fdata = {};
                    for (var key in state.formdata) {
                        fdata[key] = null;
                    }
                    state.formdata = fdata;
                    state.formid = 0;
                    state.formdata.state = "0";
                    state.formdata.id = 0;

                    // Clear Form data attributes
                    var columns = state.Columns;
                    var attr = {};
                    for (key in columns) {
                        if (columns.hasOwnProperty(key)) {
                            attr[key] = {
                                items: [],
                                readonly: false,
                                hidden: false,
                                hide: false,
                                error: false,
                                disabled: false,
                            };
                        }
                    }
                    //Vue.set(state, 'attributes', attr)


                    if (state.initialAttributes != undefined) {
                        for (key in state.initialAttributes) {
                            if (state.initialAttributes.hasOwnProperty(key)) {
                                attr[key] = state.initialAttributes[key];
                            }
                        }
                    }
                    state.attributes = attr;

                    //Clear extdata

                    for (var i = 0; i < state.extdata.length; i++) {
                        if (!state.extdata[i].attributes) state.extdata[i].attributes = {};

                        Vue.set(state.extdata[i], 'attributes', {});
                        Vue.set(state.extdata[i], 'data', []);
                    }
                }
                Utilities.log("Clearform end:", state)
            },

            SetView(state, payload) {
                Utilities.log("set view");
                state.view = payload;
            },
            SetViewPagingInfo(state, payload) {
                state.view.viewPageNumber = payload.page;
                state.view.viewItemsPerPage = payload.itemsPerPage;
            },
            UpdateViewName(state, payload) {
                Utilities.log("UpdateViewName", payload);
                //var old = state.viewName;
                state.show = 'view';
                state.viewName = payload.viewName;
            },
            updateFormInfo(state, payload) {
                //Set form groups,tabs,colums,formname and initial view. Clear parameters, attributes, children
                Utilities.log("UpdateFormInfo Payload:", payload);
                state.formdata = {};
                state.attributes = payload.attributes;
                state.initialAttributes = payload.initialAttributes;
                state.extdata = payload.extdata;
                state.TabAttributes = payload.TabAttributes;
                state.actions = [];
                state.childeren = [];
                state.files = payload.files;
                state.groups = payload.groups;
                state.tabs = payload.tabs;
                state.Columns = payload.Columns;
                state.form = payload.form;
                state.formid = payload.formid;
                state.tableName = payload.tableName;
                state.viewName = payload.viewName;
                state.ignoreActions = payload.ignoreActions;
                state.view = {};
                state.view.data = [];
                state.view.gridInfo = {};
                state.view.columns = {};
                state.view.searchkeys = [];
                state.view.gridInfo.GridTitle = '';
                state.view.errorMessage = '';
                state.view.debug = '';
                state.viewfilter = payload.viewfilter;
                state.messages = { actionInfo: '', actionResult: '', successMessage: '' };
            },
            updateDynamicFormInfo(state, payload) {
                //Set form groups,tabs,colums,formname and initial view. Clear parameters, attributes, children
                Utilities.log("UpdateFormInfo Payload:", payload);
                state.dynamicFormData = {};
                state.dynamicFormAttributes = payload.attributes;
                state.dynamicFormColumns = payload.Columns;
            },
            ChangeView(state, payload) {
                state.viewName = payload.GetViewName;
            },
            ResetSearchKeys(state) {
                var keys = state.view.searchkeys;
                Vue.set(state.view, 'searchkeys', keys);
            },
            SetFormData(state, payload) {
                Utilities.log("SetFormData", payload);
                //Utilities.log("Payload:" + JSON.stringify(payload));
                if (state.Columns != undefined) {
                    var columns = state.Columns;
                    for (var key in columns) {
                        if (payload[key] == undefined) {
                            payload[key] = "";
                        }
                    }
                }
                state.formdata = payload;
                state.formdataServer = payload;
            },
            SetFormState(state, payload) {
                Utilities.log("SetFormState", payload);
                //Utilities.log("Payload:" + JSON.stringify(payload));
                state.formdata.state = payload;
            },
            SetColumnAttribute(state, payload) {
                if (state.Columns[payload.fieldName] != undefined) {
                    state.Columns[payload.fieldName][payload.attribute] = payload.value;
                }
            },
            SetFormAttributes(state, payload) {
                if (state.attributes[payload.fieldName] != undefined) {
                    state.attributes[payload.fieldName][payload.attribute] = payload.value;
                }
            },
            SetFormAndFormId(state, payload) {
                Utilities.log("SetFormAndFormId form:" + payload.form + " formid:" + payload.formid);
                state.form = payload.form;
                state.formid = parseInt(payload.formid);
                //state.show = 'form';
            },
            SetWhatToShow(state, payload) {
                Utilities.log("#################### SetWhatToShow:" + payload.show);
                state.show = payload.show;
            },
            SetFormActions(state, payload) {
                Utilities.log("SetFormActions", payload);
                state.actions = payload;
            },

            SetFormMessages(state, payload) {
                Utilities.log("SetFormMessages", payload)
                state.messages = payload;
            },

            ClearFormMessages(state) {
                state.messages = { errorMessage: '', infoMessage: '', successMessage: '' };
            },

            AddOrUpdateExtdata(state, payload) {
                //Utilities.log("in AddOrUpdateExtdata:" + JSON.stringify(payload));
                var tableName = payload.tableName;
                var tableIndex = state.extdata.findIndex(function (t) { return t.tableName == tableName; })
                //Utilities.log("index:" + JSON.stringify(tableIndex));
                if (tableIndex == -1) {
                    // Add extdata to array
                    //Utilities.log("Push");
                    state.extdata.push(payload);
                }
                else {
                    //Utilities.log("splice");
                    // sistemdeki extdata tablosunu güncelle
                    state.extdata.splice(tableIndex, 1, payload)
                    //state.extdata[tableIndex] = payload;
                }
                //Utilities.log("endAddOrUpdateExtdata ");
            },

            SetExtdataData(state, payload) {
                Utilities.log("in SetExtdataData:", payload);
                var tableName = payload.tableName;
                var tableIndex = state.extdata.findIndex(function (t) { return t.tableName == tableName; })
                if (tableIndex != -1) {
                    // tüm extdata satırlarında rowid alanı olmalı. Yoksa bu alanı ekle
                    if (payload.data == null) {
                        payload.data = [];
                    }
                    for (var ix = 0; ix < payload.data.length; ix++) {
                        if (typeof payload.data[ix].rowid == 'undefined')
                            payload.data[ix].rowid = ix;
                    }
                    Vue.set(state.extdata[tableIndex], 'data', payload.data)
                    Vue.set(state.extdata[tableIndex], 'maxLen', payload.maxLen);

                    tableIndex = state.extdataServer.findIndex(function (t) { return t.tableName == tableName; })
                    if (tableIndex == -1) {
                        state.extdataServer.push(payload);
                    }
                    else {
                        state.extdataServer[tableIndex] = payload;
                    }


                }
                else {
                    Utilities.log("Extdata bulunamadı:" + tableName);
                }
            },

            SetExtDataSelected(state, payload) {
                Utilities.log("in SetExtDataSelected:", payload);
                var tableName = payload.tableName;
                var tableIndex = state.extdata.findIndex(function (t) { return t.tableName == tableName; })
                if (tableIndex != -1) {
                    Vue.set(state.extdata[tableIndex], 'selected', payload.data)
                }
                else {
                    Utilities.log("Extdata bulunamadı:" + tableName);
                }
            },

            ClearExtData(state, tableName) {
                Utilities.log('ClearExtData');
                var tableIndex = state.extdata.findIndex(function (t) { return t.tableName == tableName; })
                if (tableIndex != -1) {
                    Vue.set(state.extdata[tableIndex], 'attributes', {});
                    Vue.set(state.extdata[tableIndex], 'data', []);
                }
            },

            RemoveRowFromExtData(state, payload) {
                Utilities.log("RemoveRowFromExtData in store", payload)
                var tableName = payload.tableName;
                var tableIndex = state.extdata.findIndex(function (t) { return t.tableName == tableName; })
                if (tableIndex != -1) {
                    Utilities.log("tableIndex:", tableIndex);
                    var extData = state.extdata[tableIndex];
                    var removed = extData.data.filter(function (ele) {
                        return ele.rowid != payload.rowid;
                    })
                    extData.data = removed;
                    Utilities.log("tableIndex:", extData.data);
                }
            },
            AddRow2ExtData(state, payload) {
                Utilities.log("AddRow2ExtData in store", payload)
                var tableName = payload.tableName;
                var tableIndex = state.extdata.findIndex(function (t) { return t.tableName == tableName; })
                if (tableIndex != -1) {
                    Utilities.log("tableIndex:", tableIndex);
                    var extData = state.extdata[tableIndex];
                    var columns = extData.columns;
                    var rowData = payload.rowData;
                    if (rowData == null) {
                        rowData = {};
                        rowData.rowid = 0;
                        for (var key in columns) {
                            var column = columns[key];
                            var type = column.type;
                            if (type == 'int' || type == 'number')
                                rowData[key] = 0;
                            else if (type == 'date')
                                rowData[key] = null;
                            else
                                rowData[key] = "";
                        }
                    }
                    if (rowData.rowid == 0) // En büyük rowid'i bul bir fazlasını rowid olarak kullan
                    {
                        if (extData.data.length == 0) rowData["rowid"] = 1;
                        else {
                            rowData["rowid"] = extData.data.reduce((a, b) => a.rowid > b.rowid ? a : b).rowid + 1;
                        }
                        if (rowData["id"] == 0) rowData["id"] = -rowData["rowid"];
                    }
                    extData.data.push(rowData);

                    // Eklenen row için attribute ları yarat
                    var attr = { readonly: false, error: false, disabled: false, hide: false, hidden: false };
                    extData.attributes[rowData["rowid"]] = {};
                    for (key in columns) {
                        extData.attributes[rowData["rowid"]][key] = attr;
                    }

                }
                else {
                    Utilities.log("Extdata bulunamadı:" + tableName);
                }
            },
            SetUserField(state, payload) {
                state.userInfo = payload;
            },
            SetUserMenu(state, payload) {
                state.userMenu = payload;
            },
            IncrementAxiosCount(state) {

                state.axios.requestCount++;
                Utilities.log(" IncrementAxiosCount in store", state.axios.requestCount);
            },
            DecrementAxiosCount(state) {
                state.axios.requestCount--;
                Utilities.log(" DecrementAxiosCount in store", state.axios.requestCount);
            },
            ResettAxiosCount(state) {
                state.axios.requestCount = 0;
                Utilities.log(" Reset AxiosCount in store", state.axios.requestCount);
            },
            ResetTimer(state, payload) {
                try {
                    window.clearTimeout(state.timers.timerId);
                    state.timers.timerId = null;
                }
                catch (e) {
                    // ignore
                }
            },
            SetFormTimeout(state, payload) {
                try {
                    window.clearTimeout(context.state.timers.timerId);
                }
                catch (e) {
                    // ignore
                }
                state.timers.timerId = window.setTimeout(
                    payload.func, payload.time
                );
            },
            SetFormLoadingStatus(state, what) {
                state.formloading = what;
            },

            SetLoadingFormData(state, what) {
                state.LoadingFormData = what;
                state.LoadingFormDataExpiresAt = Date.now() + 1000 * 60 * 2;
                Utilities.log("Loading expires at ", state.LoadingFormDataExpiresAt)
            },
            ClearLoadingFormDataExpiresAt(state) {
                state.LoadingFormDataExpiresAt = 0;
            },

            ClearVisableTabs(state) {
                var tabs = state.tabs;
                if (typeof state.TabAttributes == "undefined") state.TabAttributes = {};
                for (var i = 0; i < tabs.length; i++) {
                    var tab = tabs[i];
                    if (state.TabAttributes[tab.order]) {
                        state.TabAttributes[tab.order].show = true;
                    }
                }
            },
            SetTabVisibility(state, payload) {
                var order = payload.order;
                var show = payload.show
                if (typeof state.TabAttributes == "undefined") state.TabAttributes = {};
                if (!state.TabAttributes[order]) {
                    state.TabAttributes[order] = {};
                }
                state.TabAttributes[order].show = show;
            },
            SetFullPage(state, payload) {
                state.fullPage = payload;
            },

            SetUserLoggedIn: (state, payload) => {
                state.UserLoggedIn = payload;
                state.userInfo.Login = state.UserLoggedIn ? "1" : "0";
            },
        },
        getters: {
            //Complete state. Use with precaution
            GetUserLoggedIn: (state) => {
                return state.UserLoggedIn;
            },
            GetLoginExists: (state) =>
            {
                return state.userInfo.Login;
            },
            GetState: (state) => {
                return state;
            },
            isUserAuthenticated: (state) => {
                return state.userInfo.Login == 1;
            },
            GetHostUrl: (state) => {

                var hostUrl =
                    window.location.protocol +
                    '//' +
                    window.location.hostname +
                    (window.location.port ? ':' + window.location.port : '') +
                    '/';
                return hostUrl;
            },
            GetTimeNow(state) {
                return state.timeNow;
            },
            GetFormLoadingStatus: (state) => {
                return state.formloading;
            },

            GetLoadingFormData(state) {
                Utilities.log(state.LoadingFormData);
                var payload = {};
                payload.LoadingFormData = state.LoadingFormData;
                payload.LoadingFormDataExpiresAt = state.LoadingFormDataExpiresAt;

                return payload;
            },
            //Form tabs and groups
            GetTabs: (state) => {
                var tabs = state.tabs;
                return tabs;
            },
            GetVisableTabs: (state) => {

                var result = [];
                var tabs = state.tabs;
                for (var i = 0; i < tabs.length; i++) {
                    var tab = tabs[i];
                    var order = tab.order;
                    if (state.TabAttributes && state.TabAttributes[order]) {
                        if (state.TabAttributes[order].show) result.push(tab);
                    }
                    else {
                        result.push(tab);
                    }
                }
                return result;

            },
            GetTabGroups: (state, getters) => {
                return (tab) => {
                    var grList = _.filter(state.groups, function (o) {
                        return o.tab == tab;
                    });
                    return grList;
                }
            },
            GetGroupColumns: (state, getters) => {
                return (group) => {
                    var columns = _.filter(state.Columns, function (o) {
                        return o.group == group;
                    })
                    columns.sort((a, b) => { return a.sira - b.sira });
                    return columns;
                }
            },

            //Form Data interface
            GetForm: (state) => {
                return state.form;
            },
            GetIgnoreActions: (state) => {
                return state.ignoreActions;
            },
            GetFormData: (state) => {
                return state.formdata;
            },
            GetFormDataFunction: (state) => {
                return (formName) => {
                    if (formName != undefined && formName == 'dynamicFormData') {
                        return state.dynamicFormData;
                    }
                    else {
                        return state.formdata;
                    }
                }
            },
            GetFormDataServer: (state) => {
                return state.formdataServer;
            },
            GetViewName: (state) => {
                return state.viewName;
            },
            GetViewType: (state) => {
                return state.view.gridInfo.type;
            },

            GetViewPagingInfo(state) {
                return { page: state.view.viewPageNumber || 1, itemsPerPage: state.view.viewItemsPerPage || 20 };
            },

            GetViewFilter(state) {
                return state.viewfilter || '';
            },
            GetViewLoading: (state) => {
                return state.view.loading;
            },
            GetWhatToShow: (state) => {
                return state.show;
            },
            GetFormColumns: (state) => {
                var Columns = state.Columns || [];
                return Columns;
            },

            GetFormColumn: (state) => {
                return (fieldName) => {
                    return state.Columns[fieldName] || {};
                }
            },
            GetFormField: (state) => (field, formName) => {
                if (formName != undefined && formName == 'dynamicFormData') {
                    var result = state.dynamicFormData[field] == undefined ? null : state.dynamicFormData[field].toString().trim();
                    Utilities.log("GetFormField Result state:", state, " field:", field, " value:", result);
                    return result;
                }
                else {
                    var result = state.formdata[field] == undefined ? null : state.formdata[field].toString().trim();
                    Utilities.log("GetFormField Result state:", state, " field:", field, " value:", result);
                    return result;
                }
            },
            GetFormState: (state) => {
                return state.formdata.state;
            },
            GetFormId: (state) => {
                return state.formid;
            },

            GetFormAttributes: (state) => {

                Utilities.log("GetFormAttributes:::", state);
                return state.attributes;
            },

            GetDynamicFormAttributes: (state) => {

                Utilities.log("GetFormAttributes:::", state);
                return state.dynamicFormAttributes;
            },

            GetFormAttribute: (state) => {
                return (fieldName, attribute) => {
                    try {
                        return state.attributes[fieldName][attribute];
                    } catch (e) {

                    }
                    return '';
                }
            },

            GetFormAction: (state) => {
                var actions = state.actions;
                Utilities.log("state.actions :::", actions);
                actions = _.sortBy(actions, function (o) { return o.sira });
                return actions;
            },

            GetFormError: (state) => {
                return state.messages;
            },


            GetExtdata: (state) => {
                return state.extdata || null;
            },

            GetExtdataServer: (state) => {
                return state.extdataServer || null;
            },

            GetExtDataExtdataInfo: (state) => {
                return (tableName) => {
                    var extData = state.extdata.find((t) => { return t.tableName == tableName });
                    if (extData != undefined) {
                        return extData.extdatainfo;
                    }
                    return {};
                }
            },


            GetFormMessages: (state) => {
                return state.messages;
            },

            //Extdata interface
            GetExtdataAttributes: (state, getters) => {
                return (extdata) => {
                    var result = _.filter(state.extdata, function (o) {
                        return o.tableName == extdata;
                    });
                    if (result.length == 1) {
                        return result[0].attributes;
                    }
                    else
                        return {};
                };
            },

            GetExtdataAttribute: (state, getters) => {
                var result = {};
                return (tableName, id, fieldName) => {
                    var extData = state.extdata.find(o => o.tableName == tableName);
                    result = extData.attributes[id][fieldName];
                }
                //return result;
            },
            GetExdataColumns: (state, getters) => {
                return (tableName) => {
                    var extData = state.extdata.find(o => o.tableName == tableName);
                    if (typeof extData != "undefined") return extData.columns;
                    return {};
                }
            },

            GetExtdataColumn: (state, getters) => {
                return (tableName, fieldName) => {
                    var result = {};
                    var extData = state.extdata.find(o => o.tableName == tableName);
                    if (typeof extData != "undefined") {
                        result = extData.colums[fieldName] || {};
                    }
                    return result;
                }
            },
            GetExtdataColumnAttribute: (state, getters) =>
            {
                return (tableName, fieldName) =>
                {
                    const extData = state.extdata.find(o => o.tableName == tableName);

                    if (extData.length == 0)
                    {
                        return {};
                    }

                    return extData.columns[fieldName];
                }
            },
            GetExdataItems: (state, getters) => {
                return (tableName) => {
                    Utilities.log("GetExdataItems", tableName, state);
                    var result = [];
                    var extData = state.extdata.find(o => o.tableName == tableName);
                    if (typeof extData != "undefined") {
                        result = extData.data;
                    }
                    else {
                        Utilities.log("TABLE BULUNAMADI:", tableName, state.extdata)
                        result = [];
                    }
                    return result;
                }
            },

            GetExdataItemsServer: (state, getters) => {
                return (tableName) => {
                    Utilities.log("GetExdataItemsServer", tableName, state);
                    var result = [];
                    var extData = state.extdataServer.find(o => o.tableName == tableName);
                    if (typeof extData != "undefined") {
                        result = extDataServer.data;
                    }
                    else {
                        Utilities.log("TABLE BULUNAMADI:", tableName, state.extdataServer)
                        result = [];
                    }
                    return result;
                }
            },

            GetExdataItem: (state, getters) => {
                return (tableName, id, fieldName) => {
                    var result = {};
                    var extData = state.extdata.find(o => o.tableName == tableName);
                    if (typeof extData != "undefined") {
                        var data = extData.data.find(i => i.id == id);
                        if (typeof data != "undefined") result = data;
                    }
                    return result;
                }
            },

            GetExdata: (state, getters) => {
                return (tableName) => {
                    var result = {};
                    var extData = state.extdata.find(o => o.tableName == tableName);
                    if (typeof extData != "undefined") {
                        result = extData;
                    }
                    return result;
                }
            },
            GetExtdataHeader: (state, getters) => {
                return (tableName) => {
                    var result = "";
                    var extData = state.extdata.find(o => o.tableName == tableName);
                    if (typeof extData != "undefined")
                        result = extData.header || '';
                    return result;
                }
            },

            GetViewColumns: (state, getters) => {
                return state.view.columns;
            },
            GetViewData: (state, getters) => {
                return state.view.data;
            },
            GetViewTitle: (state, getters) => {
                Utilities.log("GetviewTitle:", state.view)
                return state.view.gridInfo.gridTitle;
            },
            GetSearchKeys: (state, getters) => {
                return state.view.searchKeys;
            },
            GetUserInfo: (state, getters) => {
                return state.userInfo || {};
            },
            GetUserName(state, getters) {
                return state.userInfo.AdSoyad;
            },
            GetUserRoles(state, getters) {
                return state.userInfo.Role;
            },
            GetUserRolesWithoutAny(state, getters) {
                return state.userInfo.Role ? state.userInfo.Role.replace('Any;', '').replace('Any', '') : "";
            },
            GetUserUniqueId(state, getters) {
                return state.userInfo.UniqueId;
            },
            GetUserTag(state, getters) {
                Utilities.log("In GetUserTag State:", state)
                return state.userInfo == null ? "" : state.userInfo.AdSoyad + " - " + state.userInfo.Gorev;
            },
            GetUserGorev(state, getters) {
                return state.userInfo.Gorev.length > 30 ? state.userInfo.Gorev.substring(0, 25) + ' ...' : state.userInfo.Gorev;
            },
            GetUserGorevInitials(state, getters) {
                return state.userInfo.AdSoyad.split(" ").map((n, i, a) => i === 0 || i + 1 === a.length ? n[0] : null).join("");;
            },
            GetUserMenu: (state, getters) => {
                if (typeof state.userMenu == "undefined")
                    return [];

                return state.userMenu;
            },

            TransformListToItems: function (state, getters) {

                return function (list, fields) {
                    var items = [];
                    for (var i = 0; i < list.length; ++i) {
                        var listItem = list[i];
                        var item = {};
                        var text = "";
                        for (var j = 0; j < fields.length; j++) {
                            var field = fields[j];
                            var val = listItem[field];
                            if (text != "") text += " | ";
                            text += val;
                        }
                        item.text = text;
                        item.value = listItem;
                        items.push(item);
                    }
                    return items;
                }
            },
            GetAxiosCount: function (state) {
                return state.axios.requestCount;
            },

            GetFullPage(state) {
                if (typeof state.fullPage == "undefined") {
                    state.fullPage = true;
                }
                return state.fullPage;
            },

            hashCode(state, getters) {
                return function (str) {
                    var hash = 0, i, chr, len;
                    if (str.length == 0) return hash;
                    for (i = 0, len = str.length; i < len; i++) {
                        chr = str.charCodeAt(i);
                        hash = ((hash << 5) - hash) + chr;
                        hash |= 0; // Convert to 32bit integer
                    }
                    return hash;
                }
            },
        },
        actions:
        {
            KeepAlive(context) {
                var req = {};
                req.payload = {};
                req.url = context.state.hostUrl + 'api/PingBack';  // Runtime ile değiştir (getuserinfo)
                req.success = function (response) {
                    Utilities.log("KeepAlive Success:", response);
                };
                req.error = function (error) {
                    Utilities.log("KeepAlive Error:", error);
                }
                //context.dispatch("FetchDataFromServer", req)
            },
            SendFormData(context, parameters) {
                return new Promise((resolve, reject) => {

                    var payload = {};
                    payload.extdata = [];
                    payload.action = parameters.action.action;
                    payload.files = context.state.files;
                    payload.formData = context.state.formdata;
                    payload.viewName = context.state.viewName;
                    payload.form = context.state.form;
                    payload.formid = context.state.formid;

                    for (var i = 0; i < context.state.extdata.length; i++) {
                        var extdata = {};
                        extdata.tableName = context.state.extdata[i].tableName;
                        extdata.extdataInfo = context.state.extdata[i].extdatainfo;
                        extdata.data = context.state.extdata[i].data;
                        // Sadece data kısmını gönder
                        payload.extdata.push(extdata);
                    }

                    var req = {};
                    req.payload = payload;
                    req.url = context.state.hostUrl + "api/SetFormData"; // SetExternalFormData Eklenecek (Statemachine)
                    if (parameters.action.type != null && parameters.action.type == 'external') {
                        req.url = context.state.hostUrl + "api/SetExternalFormData";
                    }
                    req.success = (response) => {
                        resolve(response);

                    };
                    req.error = (error) => {
                        reject(error);
                    }
                    context.dispatch("FetchDataFromServer", req);

                });

            },
            SendFormAction(context, payload) {
                return new Promise((resolve, reject) => {
                    payload.formData = context.state.formdata;
                    payload.extdata = context.state.extdata;
                    payload.files = [];

                    var req = {};
                    req.payload = payload;
                    req.url = context.state.hostUrl + "api/FormAction";
                    req.success = function (response) {
                        Utilities.log("Got respose From SendFormAction:", response);
                        resolve(response);
                    };
                    req.error = function (error) {
                        Utilities.log("post eror in SendFormAction:", error);
                        reject(error);
                    }
                    context.dispatch("FetchDataFromServer", req);
                })
            },
            SendFormActionDynamic(context, payload) {
                return new Promise((resolve, reject) => {
                    var req = {};
                    req.payload = payload;
                    req.url = context.state.hostUrl + "api/FormAction";
                    req.success = function (response) {
                        Utilities.log("Got respose From SendFormAction:", response);
                        resolve(response);
                    };
                    req.error = function (error) {
                        Utilities.log("post eror in SendFormAction:", error);
                        reject(error);
                    }
                    context.dispatch("FetchDataFromServer", req);
                })
            },

            GetConfigParameter(context, parameterName) {
                Utilities.log("GetConfigParameter", parameterName);
                return new Promise((resolve, reject) => {
                    var req = {};
                    req.payload = {};
                    req.payload.search = parameterName;
                    req.payload.queryName = 'GetConfigParameter';
                    req.payload.parameters = {};
                    req.url = context.state.hostUrl + 'api/LOV';
                    req.success = function (response) {
                        if (response.data.list.length > 0) {
                            resolve(response.data.list[0]);
                        }
                    };
                    req.error = function (error) {
                        Utilities.log("Parametre alımında hata", error);
                        reject(error);
                    }
                    context.dispatch("FetchDataFromServer", req);
                })
            },

            GetLocationRoles(context, locationCode) {
                Utilities.log("GetLocationRoles", locationCode);
                return new Promise((resolve, reject) => {
                    var req = {};
                    req.payload = {};
                    req.payload.search = locationCode;
                    req.payload.queryName = 'GetLocationRoles'; // context.state.form + "_" + payload.payload.fieldName;
                    req.payload.parameters = {};
                    req.url = context.state.hostUrl + 'api/LOV';
                    req.success = function (response) {
                        if (response.data.list.length > 0) {
                            resolve(response.data.list[0]);
                        }
                    };
                    req.error = function (error) {
                        Utilities.log("Kullanıcı rollerinin alımında hata", error);
                        reject(error);
                    }
                    context.dispatch("FetchDataFromServer", req);
                })
            },

            GetLocationInfoByLocationCode(context, locationCode) {
                return new Promise((resolve, reject) => {
                    var req = {};
                    req.payload = {};
                    req.payload.search = locationCode;
                    req.payload.queryName = 'GetLocationInfoByLocationCode';
                    req.payload.parameters = {};
                    req.url = context.state.hostUrl + 'api/LOV';
                    req.success = function (response) {
                        if (response.data.list.length > 0) {
                            resolve(response.data.list[0]);
                        }
                    };
                    req.error = function (error) {
                        Utilities.log("Lokasyon bilgileri alımında hata", error);
                        reject(error);
                    }
                    context.dispatch("FetchDataFromServer", req);
                });

            },
            GetUserInfo(context) {

                return new Promise((resolve, reject) => {
                    var userInfo = context.userInfo;
                    var retvalue = {};
                    retvalue.userInfo = userInfo;
                    retvalue.resultCode = 0;
                    Utilities.log(" in GetUserInfo CurrentUserInfo:", userInfo);
                    if (typeof userInfo == "undefined" || userInfo == null || typeof userInfo.AdSoyad == "undefined" || userInfo.AdSoyad == null) {
                        Utilities.log("Go Fetch user info");
                        var req = {};
                        req.payload = {};
                        req.payload.action = 'GetUserInfo';
                        req.payload.formdata = {
                            formInputs: {},
                            extData: [{}],
                        };
                        req.url = context.state.hostUrl + 'api/login';
                        req.success = function (response) {
                            try {
                                Utilities.log("Got user info from server:", response);
                                if (response.data.errorCode != 0) {
                                    router.push({ name: 'login' });
                                    reject(response);
                                }
                                else {

                                    var userInfo = {};

                                    userInfo.SicilNo = response.data.result.SicilNo;
                                    userInfo.AdSoyad = response.data.result.AdSoyad;
                                    userInfo.Role = response.data.result.Role;
                                    userInfo.Gorev = response.data.result.Gorev;
                                    userInfo.Login = response.data.result.Login;
                                    userInfo.FirmaNo = response.data.result.FirmaNo;
                                    userInfo.UniqueId = response.data.result.UniqueId;
                                    userInfo.approved = response.data.result.approved;
                                    userInfo.details = response.data.result.details;

                                    context.commit("SetUserField", userInfo);
                                    Utilities.log("Set user info in getuserinfo", userInfo, context.state);
                                    resolve(response);
                                }
                            } catch (e) {
                                Utilities.log(" Error in fetch success function", e)
                            }

                        }
                        req.error = function (error) {
                            Utilities.log("Kullanıcı alımında hata", error);
                            reject(error);
                        }
                        //Utilities.log("Before FetchDataFromServer in GetLOV", req);
                        context.dispatch("FetchDataFromServer", req);
                    }
                    else {
                        Utilities.log(" in GetUserInfo from cache", retvalue)
                        resolve(retvalue);
                    }
                });
            },
            GetI18nData(context, payload) {

                return new Promise((resolve, reject) => {
                    var retvalue = {};
                    retvalue.resultCode = 0;
                    Utilities.log("Go Fetch GetI18nData");
                    var req = {};
                    req.payload = {};
                    req.payload.action = 'GetI18nData';
                    req.payload.formdata = {
                        formInputs: { lang: payload.lang },
                        extData: [{}],
                    };

                    context.commit('SetHostUrl');
                    req.url = context.state.hostUrl + 'api/Login';
                    req.success = function (response) {
                        try {
                            Utilities.log("Got I18nData from server:", response);
                            var retvalue = {};
                            retvalue.resultCode = response.data.resultCode;
                            retvalue.data = {};
                            if (response.data != undefined && response.data.list != undefined && response.data.list.length == 1) {
                                retvalue.data = response.data.list[0];
                            }
                        } catch (e) {
                            Utilities.log(" Error in fetch success function", e)
                        }
                        resolve(retvalue);
                    }
                    req.error = function (error) {
                        Utilities.log("I18nData hata", error);
                        reject(error);
                    }
                    //Utilities.log("Before FetchDataFromServer in GetLOV", req);
                    context.dispatch("FetchDataFromServer", req);
                    var unixtime_ms = new Date().getTime();
                    while (new Date().getTime() < unixtime_ms + 2000) { }
                });
            },
            GetLovDynamic(context, payload) {

                return new Promise((resolve, reject) => {
                    var retvalue = {};
                    retvalue.resultCode = 0;
                    Utilities.log("Go Fetch GetLovDynamic");
                    var req = {};
                    req.payload = {};
                    req.payload.queryName = payload.queryName;
                    req.payload.parameters = payload.parameters;

                    context.commit('SetHostUrl');
                    req.url = context.state.hostUrl + 'api/LOV';
                    req.success = function (response) {
                        try {
                            Utilities.log("Got GetLovDynamic from server:", response);
                            var retvalue = {};
                            retvalue.resultCode = response.data.resultCode;
                            retvalue.data = {};
                            if (response.data != undefined && response.data.list != undefined) {
                                retvalue.data = response.data.list;
                            }
                        } catch (e) {
                            Utilities.log(" Error in fetch success function", e)
                        }
                        resolve(retvalue);
                    }
                    req.error = function (error) {
                        Utilities.log("GetLovDynamic hata", error);
                        reject(error);
                    }
                    //Utilities.log("Before FetchDataFromServer in GetLOV", req);
                    context.dispatch("FetchDataFromServer", req);
                });
            },
            GetView(context, inpayload) {
                context.commit('ResetTimer', '');
                return new Promise((resolve, reject) => {
                    if (inpayload.form == "" || inpayload.viewName == "") return;
                    var payload = {};
                    payload.form = inpayload.form;
                    payload.formid = inpayload.formid;
                    payload.viewName = inpayload.viewName;
                    payload.tableName = context.state.tableName ? context.state.tableName : "";
                    payload.parameters = inpayload.parameters;
                    payload.viewfilter = inpayload.viewfilter;
                    //TODO: parametreleri hesapla
                    var req = {};
                    req.payload = payload;
                    req.url = context.state.hostUrl + "api/View";  // Eklenecek
                    req.success = inpayload.success;
                    req.error = inpayload.error;
                    context.dispatch("FetchDataFromServer", req);
                });

            },

            GetFormData(context, payload) {
                //if (context.state.LoadingFormData) return;
                //context.state.LoadingFormData = true;
                context.commit("ResettAxiosCount");
                context.commit("ClearForm");
                context.commit('ResetTimer', '');
                context.commit('SetFormLoadingStatus', true);
                return new Promise((resolve, reject) => {
                    context.commit('SetFormAndFormId', payload);
                    context.commit('SetFormMessages', { errorMessage: '', infoMessage: '', successMessage: '' })
                    payload.columns = context.state.columns;
                    payload.tableName = context.state.tableName;
                    var req = {};
                    req.payload = payload;
                    req.url = context.state.hostUrl + "api/GetFormData";
                    req.success = function (response) {
                        context.commit('SetFormLoadingStatus', false);
                        //context.state.LoadingFormData = false;
                        context.commit('SetFormData', response.data.data);
                        if (!payload.ignoreActions) context.commit('SetFormActions', response.data.actions);
                        context.commit('ClearVisableTabs');

                        context.commit('SetFormAndFormId', {
                            form: payload.form,
                            formid: payload.formid
                        });

                        resolve(response);
                    };
                    req.error = function (error) {
                        context.commit('SetFormLoadingStatus', false);
                        //context.state.LoadingFormData = false;
                        Utilities.log("post eror in GetFormData:", error);
                        // error reporting})
                        reject(error);
                    }
                    //Utilities.log("Before FetchDataFromServer in GetFormData", req);
                    context.dispatch("FetchDataFromServer", req);
                });
            },
            GetExtData(context, table) {
                return new Promise((resolve, reject) => {
                    Utilities.log("in GetExtData table:", table + " State:", context.state);
                    context.commit('ClearExtData', table);

                    var payload = {};
                    payload.form = context.state.form;
                    payload.formid = context.state.formid;
                    var tableIndex = context.state.extdata.findIndex(function (t) { return t.tableName == table; });
                    if (tableIndex == -1) {
                        Utilities.log("Ext Table yok " + table);
                        context.state.messages = {
                            errorMessage: "Ext Table yok " + table,
                            infoMessage: '',
                            successMessage: '',
                        }
                        reject(context.state.messages);
                        return;
                    }

                    payload.extdatainfo = context.state.extdata[tableIndex].extdatainfo;

                    if (typeof payload.extdatainfo.queryName != "undefined" && payload.extdatainfo.queryName == "Local") {
                        // Local tablolar için sunucuya gitme
                        return;
                    }

                    payload.tableName = context.state.extdata[tableIndex].tableName;
                    payload.columns = context.state.extdata[tableIndex].columns;
                    payload.parameters = {};
                    for (var key in context.state.extdata[tableIndex].parameters) {
                        if (context.state.extdata[tableIndex].parameters.hasOwnProperty(key)) {
                            var par = context.state.extdata[tableIndex].parameters[key];
                            if (par == "") continue;
                            if (par == "**") {
                                payload.parameters = context.state.formdata;
                                break;
                            }
                            if (par.substring(0, 1) == '*') {
                                //* Refers to form field
                                if (context.state.formdata[par.substring(1)] == "undefined") continue;
                                var val = context.state.formdata[par.substring(1)] || '';
                                payload.parameters[par.substring(1)] = val;
                            }
                            else {
                                // Constant value
                                payload.parameters[key] = par;
                            }
                        }
                    }
                    var req = {};
                    req.payload = payload;
                    req.url = context.state.hostUrl + "api/ExtData";


                    req.success = (response) => {
                        Utilities.log("GOT Extdata result from server *************", response);
                        var errmsg = response.data.errorMessage;
                        if (errmsg != "" && errmsg != null) {
                            context.commit('ClearExtData', table);
                            reject(errmsg);
                            return;
                        }

                        var payload = {};
                        payload.tableName = table;
                        payload.data = response.data.data;
                        payload.maxLen = response.data.maxC;
                        context.commit('SetExtdataData', payload);
                        if (context.state.axios.requestCount == 0) {
                            Utilities.log("COUNTER 0");
                        }
                        response.tableName = table;
                        resolve(response);
                    };

                    req.error = function (error) {
                        Utilities.log("post eror in GetExtData:" + JSON.stringify(error));
                        reject(error);
                    };

                    context.dispatch("FetchDataFromServer", req);
                });
            },
            GetOnayExt(context, isPortal) {
                return new Promise((resolve, reject) => {
                    Utilities.log("in GetOnayExt State:", context.state);

                    var payload = {};
                    payload.form = context.state.form;
                    payload.formid = context.state.formid;
                    payload.isPortal = isPortal;
                    var req = {};
                    req.payload = payload;
                    req.url = context.state.hostUrl + "api/GetOnayExt";


                    req.success = (response) => {
                        Utilities.log("GOT OnayExt result from server *************", response);
                        var errmsg = response.data.errorMessage;
                        if (errmsg) {
                            reject(errmsg);
                            return;
                        }

                        var payload = {};
                        payload.data = response.data.data;
                        if (context.state.axios.requestCount == 0) {
                            Utilities.log("COUNTER 0");
                        }
                        resolve(response);
                    };

                    req.error = function (error) {
                        Utilities.log("post eror in GetExtData:" + JSON.stringify(error));
                        reject(error);
                    };
                    context.dispatch("FetchDataFromServer", req);
                });
            },
            GetFormRoles(context, payload) {
                return new Promise((resolve, reject) => {

                    var req = {};
                    req.payload = {};
                    req.payload.search = '';
                    req.payload.queryName = 'GetFormRoles'; // context.state.form + "_" + payload.payload.fieldName;
                    req.payload.parameters = { form: payload.form, formid: payload.formid, parameter: payload.parameter };
                    req.url = context.state.hostUrl + 'api/LOV';
                    req.success = function (response) {
                        Utilities.log("Got Data in GetFormRoles", response)
                        resolve(response);
                    }
                    req.error = function (error) {
                        Utilities.log("Kullanıcı alımında hata", error);
                        reject(error);
                    }
                    //Utilities.log("Before FetchDataFromServer in GetLOV", req);
                    context.dispatch("FetchDataFromServer", req);
                });
            },
            GetExtDataAsynch(context, table) {
                return new Promise((resolve, reject) => {
                    Utilities.log("in GetExtData table:", table + " State:", context.state);
                    context.commit('ClearExtData', table);

                    var payload = {};
                    payload.form = context.state.form;
                    payload.formid = context.state.formid;
                    var tableIndex = context.state.extdata.findIndex(function (t) { return t.tableName == table; });
                    if (tableIndex == -1) {
                        Utilities.log("Ext Table yok " + table);
                        return;
                    }

                    payload.extdatainfo = context.state.extdata[tableIndex].extdatainfo;

                    if (typeof payload.extdatainfo.queryName != "undefined" && payload.extdatainfo.queryName == "Local") {
                        // Local tablolar için sunucuya gitme
                        return;
                    }

                    payload.tableName = context.state.extdata[tableIndex].tableName;
                    payload.columns = context.state.extdata[tableIndex].columns;
                    payload.parameters = {};
                    for (var key in context.state.extdata[tableIndex].parameters) {
                        if (context.state.extdata[tableIndex].parameters.hasOwnProperty(key)) {
                            var par = context.state.extdata[tableIndex].parameters[key];
                            if (par == "") continue;
                            if (par == "**") {
                                payload.parameters = context.state.formdata;
                                break;
                            }
                            if (par.substring(0, 1) == '*') {
                                //* Refers to form field
                                if (context.state.formdata[par.substring(1)] == "undefined") continue;
                                var val = context.state.formdata[par.substring(1)] || '';
                                payload.parameters[key] = val;
                            }
                            else {
                                // Constant value
                                payload.parameters[key] = par;
                            }
                        }
                    }
                    var req = {};
                    req.payload = payload;
                    req.url = context.state.hostUrl + "api/GetExtData";
                    req.success = (response) => {
                        var payload = {};
                        payload.tableName = table;
                        payload.data = response.data.data;
                        payload.maxLen = response.data.maxC;
                        context.commit('SetExtdataData', payload);
                        if (context.state.axios.requestCount == 0) {
                            Utilities.log("COUNTER 0");
                        }
                        resolve(payload.data);
                    };

                    req.error = function (error) {
                        Utilities.log("post eror in GetExtData:" + JSON.stringify(error));
                        // error reporting})
                        reject(error);
                    };

                    context.dispatch("FetchDataFromServer", req);
                });

            },

            GetLOV(context, inpayload) {
                var req = {};
                req.payload = {};
                req.payload.search = inpayload.payload.search;
                req.payload.queryName = inpayload.queryName; // context.state.form + "_" + payload.payload.fieldName;

                var parameters = inpayload.payload.parameters;
                req.payload.parameters = {};
                var formidVar = false;
                for (var key in parameters) {
                    if (key == 0 && parameters[0] == "**") {
                        // Tüm form datasını gönder
                        req.payload.parameters = context.state.formdata;
                        break;
                    }
                    if (parameters.hasOwnProperty(key)) {
                        var par = parameters[key];

                        if (par == '*formid') formidVar = true;
                        if (par == "") continue;
                        if (typeof par == "string" && par.substring(0, 1) == '*') {
                            //* Refers to form field
                            var val = context.state.formdata[par.substring(1)];
                            if (typeof val == "undefined") {
                                Utilities.log("ERROR in GetLOV key", par, " is not in formdata", context.state.formdata);
                                req.payload.parameters[par.substring(1)] = "";
                            }
                            req.payload.parameters[par.substring(1)] = val;
                        }
                        else {
                            // Constant value
                            req.payload.parameters[key] = par;
                        }
                    }
                }
                if (!formidVar) req.payload.parameters['formid'] = context.state.formid;

                //clear old items
                var clearPayload = {
                    tableName: inpayload.payload.tableName, // Form datasi ile tableName ='formdata'
                    fieldName: inpayload.payload.column.name,
                    attributeName: "items",
                    id: inpayload.payload.id,
                    value: [],
                };
                context.commit("SetFieldAttributes", clearPayload);

                if (inpayload.notAuthorizedRequest == true) {
                    var accountPayload = {
                        action: "GetLOV",
                        formdata: {
                            formInputs: req.payload,
                        }
                    };
                    context.dispatch("AccountAction", accountPayload)
                        .then(response => {
                            try {
                                var label = inpayload.payload.column.itemText;
                                var successFunction = inpayload.success;
                                var items = context.getters.TransformListToItems(response.data.listResult, label);
                                var payload2 = {
                                    tableName: inpayload.payload.tableName, // Form datasi ile tableName ='formdata'
                                    fieldName: inpayload.payload.column.name,
                                    attributeName: "items",
                                    id: inpayload.payload.id,
                                    value: items
                                };
                                context.commit("SetFieldAttributes", payload2);
                                if (typeof successFunction == "function") {
                                    successFunction(response, req);
                                }
                            } catch (e) {
                                Utilities.log(" Error in fetch success function", e)
                            }
                        });
                }
                else {
                    req.url = (typeof inpayload.url != "undefined" && inpayload.url != "") ? inpayload.url : 'api/LOV';
                    req.success = function (response) {
                        try {
                            var label = inpayload.payload.column.itemText;
                            var successFunction = inpayload.success;
                            var items = context.getters.TransformListToItems(response.data.list, label);
                            var payload2 = {
                                tableName: inpayload.payload.tableName, // Form datasi ile tableName ='formdata'
                                fieldName: inpayload.payload.column.name,
                                attributeName: "items",
                                id: inpayload.payload.id,
                                value: items
                            };
                            context.commit("SetFieldAttributes", payload2);
                            if (typeof successFunction == "function") {
                                successFunction(response, req, payload2);
                            }
                        } catch (e) {
                            Utilities.log(" Error in fetch success function", e)
                        }

                    }

                    req.error = function (error) {
                        var failFunction = inpayload.error;
                        if (typeof failFunction == "function") {
                            failFunction(error, req)
                        }
                    }
                    //Utilities.log("Before FetchDataFromServer in GetLOV", req);
                    context.dispatch("FetchDataFromServer", req);
                }
            },
            GetMenu(context) {
                var req = {};
                req.payload = {};
                req.payload.search = '';
                req.payload.queryName = 'GetMenu'; // context.state.form + "_" + payload.payload.fieldName;
                req.payload.parameters = {};
                req.url = context.state.hostUrl + 'api/LOV';
                req.success = function (response) {
                    try {
                        var menu = [];
                        if (response.data.list.length > 0) {
                            menu = response.data.list;

                        }
                        context.commit("SetUserMenu", menu);
                    } catch (e) {
                        Utilities.log(" Error in Getmenu success function", e)
                    }
                }
                req.error = function (error) {
                    Utilities.log("Kullanıcı menu alımında hata", error);
                }
                context.dispatch("FetchDataFromServer", req);
            },
            GetAccordionMenu(context) {
                return new Promise((resolve, reject) => {
                    var req = {};
                    req.payload = {};
                    req.payload.search = '';
                    req.payload.queryName = 'GetAccordionMenu'; // context.state.form + "_" + payload.payload.fieldName;
                    req.payload.parameters = {};
                    req.url = context.state.hostUrl + 'api/LOV';
                    req.success = function (response) {
                        try {
                            var menu = [];
                            if (response.data.menu != null && response.data.menu.length > 0) {
                                menu = response.data.menu;
                            }
                            resolve(menu);
                        } catch (e) {
                            Utilities.log(" Error in GetAccordionMenu success function", e, response)
                            reject(" Error in GetAccordionMenu success function:" + e);
                        }
                    }
                    req.error = function (error) {
                        Utilities.log("Kullanıcı menu alımında hata", error);
                        reject(error);
                    }
                    context.dispatch("FetchDataFromServer", req);
                })
            },
            CreateAssetRecords(context, payload) {
                return new Promise((resolve, reject) => {
                    var req = {};

                    req.payload = { ...payload.data };
                    req.payload.lines = payload.lines;
                    req.payload.formdata = payload.formdata;
                    req.success = function (response) {
                        resolve(response);
                    };
                    req.error = function (error) {
                        reject(error)
                    };
                    req.url = context.state.hostUrl + "controllers/CreateAssets.ashx"; // Run time ile değiştir !!
                    context.dispatch("FetchDataFromServer", req);
                })
            },
            ExcelRequestTransfer(context, payload) {
                return new Promise((resolve, reject) => {
                    var req = {};

                    req.payload = { ...payload.data };
                    req.payload.lines = payload.lines;
                    req.payload.action = payload.action;
                    req.payload.formdata = payload.formdata;
                    req.success = function (response) {
                        resolve(response);
                    };
                    req.error = function (error) {
                        reject(error)
                    };
                    req.url = context.state.hostUrl + "controllers/UploadExcelAction.ashx";  // Formaction ile değiştir
                    context.dispatch("FetchDataFromServer", req);
                })
            },

            PingServer(context) {

                //var req = {};
                //req.payload = {};

                //req.success = function (response) {
                //    Utilities.log("PING", response);
                //};
                //req.error = function (error) {
                //    Utilities.log("PING FAILED");
                //};
                //req.url = context.state.hostUrl + "controllers/PingBack.ashx";  // runtime ile değiştir (GetUserInfo)
                //context.dispatch("FetchDataFromServer", req);
            },

            RunTime(context, payload) {
                return new Promise((resolve, reject) => {
                    var req = {};
                    req.payload = {};
                    req.payload.data = payload.data;
                    req.payload.action = payload.action;
                    req.success = function (response) {
                        resolve(response);
                    };
                    req.error = function (error) {
                        reject(error)
                    };
                    req.url = context.state.hostUrl + "api/RunTime";
                    context.dispatch("FetchDataFromServer", req);
                })
            },
            GetReport(context, payload) {
                return new Promise((resolve, reject) => {
                    var req = {};

                    req.payload = payload;

                    req.success = function (response) {
                        resolve(response);
                    };
                    req.error = function (error) {
                        reject(error)
                    };
                    req.url = context.state.hostUrl + "controllers/GetReport.ashx"; // Eklenecek
                    context.dispatch("FetchDataFromServer", req);
                })
            },

            AccountActionV2(context, data)
            {
                return new Promise((resolve, reject) =>
                {
                    const { method, payload } = data;

                    const req = {
                        url: ['/api/Account/', method].join(''),
                        payload: payload,
                        success: function (response)
                        {
                            resolve(response);
                        },
                        error: function (error)
                        {
                            reject(error)
                        }
                    };

                    context.dispatch("FetchDataFromServerV2", req);
                });
            },

            FetchDataFromServerV2(context, req)
            {
                if (req.url.startsWith('/api/Account/') === false)
                {
                    if (context.state.userInfo.Login == "0")
                    {
                        console.log("Kullanıcı login değil. İstek iletilmedi url:" + req.url);
                        return;
                    }
                }

                const _http = axios.create({
                    headers: {
                        "Cache-Control": "no-cache",
                        "Current-Language": Utilities.GetCurrentLanguage(),
                        "Request-Id": context.getters.hashCode(JSON.stringify(req.payload))
                    },
                    timeout: 60000,
                    withCredentials: true,
                    maxRedirects: 0
                });

                context.commit('IncrementAxiosCount');

                _http.post(req.url, req.payload)
                    .then(function (response)
                    {
                        req.success(response);
                    })
                    .catch(function (error)
                    {
                        req.error(error.response)
                    })
                    .finally(function ()
                    {
                        context.commit('DecrementAxiosCount');
                    });
            },

            AccountAction(context, payload) {
                return new Promise((resolve, reject) => {
                    var req = {};
                    req.payload = payload;

                    req.success = function (response) {
                        resolve(response);
                    };
                    req.error = function (error) {
                        reject(error)
                    };
                    context.commit('SetHostUrl');
                    req.url = context.state.hostUrl + "api/Login";
                    if (payload.controller != undefined) {
                        req.url = context.state.hostUrl + "api/" + payload.controller + "/" + payload.method;
                    }
                    context.dispatch("FetchDataFromServer", req);
                })
            },


            FetchDataFromServer(context, req) {
                // User login olmadan önce sadece /Acount handler'ina erişime musade et.
                var url = req.url;
                if (url.toLowerCase().indexOf("/login") == -1) {
                    if (context.state.userInfo.Login == "0") {
                        console.log("Kullanıcı login değil. İstek iletilmedi url:" + url);
                        return;
                    }
                }
                // Bazı çağırılar için cache kullanılabilir. Örn: get_menu, get_uniteler vs
                // Cache için url ve payload hash lenerek cache lenebilir
                var payload = req.payload;
                var date = new Date(); // Dakika kısmına kadar al
                var timestamp =
                    date.getFullYear() + "-" +
                    ("00" + (date.getMonth() + 1)).slice(-2) + "-" +
                    ("00" + date.getDate()).slice(-2) + "T" +
                    ("00" + date.getHours()).slice(-2) + ":" +
                    ("00" + date.getMinutes()).slice(-2);
                payload.timestamp = timestamp;
                var strToHash = JSON.stringify(payload);
                var hash = context.getters.hashCode(strToHash);
                var SecretCode = hash;
                //payload.SecretCode = hash;

                if (url.toLowerCase().indexOf("http") != 0 && url.indexOf("//") != 0) {
                    // Relatif URL. başına base url'i ekle
                    url = context.state.hostUrl + url;
                }

                var timeout = 600000; // 10 dk
                if (url.indexOf('CreateAssets.ashx') != -1) // runtime ile değiştir. Runtime a timeout parametresi ekl
                    timeout = timeout * 10;
                else timeout = timeout * 3;
                //if (!url) Utilities.log("Url hatası:" + url)
                var success = req.success;
                var error = req.error;
                const http = axios.create({
                    // baseURL: "/",
                    headers: {
                        "Cache-Control": "no-cache",
                        "Current-Language": Utilities.GetCurrentLanguage(),
                        "requestId": SecretCode,
                    },
                    // cache will be enabled by default
                    timeout: timeout,
                    withCredentials: true,
                    maxRedirects: 0,
                    transformResponse: [
                        (data) => {
                            Utilities.log("SERVER RAW DATA:", data);
                            let resp;

                            try {
                                resp = JSON.parse(data);
                            } catch (error) {
                                Utilities.log(`[requestClient] Error parsing response JSON data - ${JSON.stringify(error)}`);
                                throw Error(`[requestClient] Error parsing response JSON data - ${JSON.stringify(error)}`);
                            }
                            Utilities.log("JSON Object:", resp);
                            return resp;
                        }
                    ]
                });

                Utilities.log("FetchDataFromServer Post To Server url:" + url + " payload:", payload);
                //var outpayload = { D:\Projects\KForms\KForms\BEYMENDEV\VueClient\src\store.js JSON.stringify(payload) };

                context.commit('IncrementAxiosCount');
                http.post(url, payload)
                    .then(function (response) {
                        Utilities.log("GET DATA FROM SERVER", response)
                        Utilities.log("GET STATUS FROM SERVER", response.status)
                        Utilities.log("GET HEADERS FROM SERVER", response.headers)
                        context.commit('DecrementAxiosCount');
                        try {
                            success(response);
                        } catch (e) {
                            Utilities.log("FetchDataFromServer Success function eror:", e);
                            error(e);
                        }
                    })
                    .catch(function (err) {
                        context.commit('DecrementAxiosCount');
                        Utilities.log("post eror in FetchFormData:", err);

                        if (err.response.status == 401) {
                            router.push({ name: 'login' });
                        }
                    });
            },
        }
    })

