<template>
    <v-row class="mb-60">
        <v-col v-if="FormLoadingStatus">
            <v-alert dense text tile border="left" color="primary" class="mb-3 p-auto">
                <v-col cols="12"
                       md="12">
                    <v-skeleton-loader class="mx-auto"
                                       max-height="20"
                                       type="image"></v-skeleton-loader>
                </v-col>
            </v-alert>
            <v-container class="accent lighten-5">
                <v-row>
                    <v-col cols="12"
                           md="4">
                        <v-skeleton-loader class="mx-auto"
                                           max-height="40"
                                           type="image"></v-skeleton-loader>
                    </v-col>
                    <v-col cols="12"
                           md="4">
                        <v-skeleton-loader class="mx-auto"
                                           max-height="40"
                                           type="image"></v-skeleton-loader>
                    </v-col>
                    <v-col cols="12"
                           md="4">
                        <v-skeleton-loader class="mx-auto"
                                           max-height="40"
                                           type="image"></v-skeleton-loader>
                    </v-col>
                </v-row>
            </v-container>
            <v-skeleton-loader class="mx-auto"
                               type="divider"></v-skeleton-loader>
            <v-expansion-panels accordion multiple tile flat v-model="panel">
                <v-expansion-panel>
                    <v-expansion-panel-header class="font-weight-bold py-6 accent lighten-5" style="min-height: 0 !important">
                        <v-skeleton-loader class="mr-auto"
                                           max-height="20"
                                           max-width="300"
                                           type="image"></v-skeleton-loader>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="py-5" style="">
                        <v-row>
                            <v-col cols="6"
                                   md="6">
                                <v-skeleton-loader class="mx-auto"
                                                   max-height="40"
                                                   type="image"></v-skeleton-loader>

                            </v-col>
                            <v-col cols="6"
                                   md="6">
                                <v-skeleton-loader class="mx-auto"
                                                   max-height="40"
                                                   type="image"></v-skeleton-loader>

                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6"
                                   md="6">
                                <v-skeleton-loader class="mx-auto"
                                                   max-height="40"
                                                   type="image"></v-skeleton-loader>

                            </v-col>
                            <v-col cols="6"
                                   md="6">
                                <v-skeleton-loader class="mx-auto"
                                                   max-height="40"
                                                   type="image"></v-skeleton-loader>

                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6"
                                   md="6">
                                <v-skeleton-loader class="mx-auto"
                                                   max-height="40"
                                                   type="image"></v-skeleton-loader>

                            </v-col>
                            <v-col cols="6"
                                   md="6">
                                <v-skeleton-loader class="mx-auto"
                                                   max-height="40"
                                                   type="image"></v-skeleton-loader>

                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4"
                                   md="4">
                                <v-skeleton-loader class="mx-auto"
                                                   max-height="40"
                                                   type="image"></v-skeleton-loader>

                            </v-col>
                            <v-col cols="4"
                                   md="4">
                                <v-skeleton-loader class="mx-auto"
                                                   max-height="40"
                                                   type="image"></v-skeleton-loader>

                            </v-col>
                            <v-col cols="4"
                                   md="4">
                                <v-skeleton-loader class="mx-auto"
                                                   max-height="40"
                                                   type="image"></v-skeleton-loader>

                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12"
                                   md="12">
                                <v-skeleton-loader class="mx-auto"
                                                   max-height="65"
                                                   type="image"></v-skeleton-loader>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12"
                                   md="12">
                                <v-skeleton-loader class="mx-auto"
                                                   max-height="90"
                                                   type="image"></v-skeleton-loader>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                    <v-expansion-panel-header class="font-weight-bold py-6 accent lighten-5" style="min-height: 0 !important">
                        <v-skeleton-loader class="mr-auto"
                                           max-height="30"
                                           max-width="300"
                                           type="image"></v-skeleton-loader>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="py-5" style="">
                        <v-row>
                            <v-col cols="6"
                                   md="6">
                                <v-skeleton-loader class="mx-auto"
                                                   max-height="40"
                                                   type="image"></v-skeleton-loader>

                            </v-col>
                            <v-col cols="6"
                                   md="6">
                                <v-skeleton-loader class="mx-auto"
                                                   max-height="40"
                                                   type="image"></v-skeleton-loader>

                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6"
                                   md="6">
                                <v-skeleton-loader class="mx-auto"
                                                   max-height="40"
                                                   type="image"></v-skeleton-loader>

                            </v-col>
                            <v-col cols="6"
                                   md="6">
                                <v-skeleton-loader class="mx-auto"
                                                   max-height="40"
                                                   type="image"></v-skeleton-loader>

                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6"
                                   md="6">
                                <v-skeleton-loader class="mx-auto"
                                                   max-height="40"
                                                   type="image"></v-skeleton-loader>

                            </v-col>
                            <v-col cols="6"
                                   md="6">
                                <v-skeleton-loader class="mx-auto"
                                                   max-height="40"
                                                   type="image"></v-skeleton-loader>

                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4"
                                   md="4">
                                <v-skeleton-loader class="mx-auto"
                                                   max-height="40"
                                                   type="image"></v-skeleton-loader>

                            </v-col>
                            <v-col cols="4"
                                   md="4">
                                <v-skeleton-loader class="mx-auto"
                                                   max-height="40"
                                                   type="image"></v-skeleton-loader>

                            </v-col>
                            <v-col cols="4"
                                   md="4">
                                <v-skeleton-loader class="mx-auto"
                                                   max-height="40"
                                                   type="image"></v-skeleton-loader>

                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12"
                                   md="12">
                                <v-skeleton-loader class="mx-auto"
                                                   max-height="65"
                                                   type="image"></v-skeleton-loader>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12"
                                   md="12">
                                <v-skeleton-loader class="mx-auto"
                                                   max-height="90"
                                                   type="image"></v-skeleton-loader>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-col>
        <v-col v-else>
            <div v-if="durum != null">
                <v-alert dense dark tile border="left" v-if="AnyNotApprovedField" icon="mdi-timer-sand" color="warning" class="mb-3">
                    {{ $t('FL_Status') }}: {{ $t('IM_HaveUnconfirmedInformation') }}
                </v-alert>
                <v-alert dense dark tile border="left" v-else icon="mdi-information" color="warning" class="mb-3">
                    {{ $t('FL_Status') }}: {{ durum }}
                </v-alert>
            </div>
            <v-tabs v-model="tab" grow flat color="secondary">
                <v-tab v-for="t in GetDynoVisibleTabs" :key="t.order" :href="`#Tab-${t.order}`" class="accent lighten-5" :class="`taborder-${t.order}` + ' font-weight-bolder '">
                    {{ $t(t.header) }}
                </v-tab>
                <v-tab-item v-for="t in GetDynoVisibleTabs"
                            :key="t.order"
                            :value="'Tab-' + t.order" class="grey lighten-4">

                    <v-expansion-panels accordion multiple tile v-model="panel">
                        <v-expansion-panel v-for="(g, index) in GetDynoTabGroups(t.order)"
                                           :key="g.order" :value="0">
                            <v-expansion-panel-header class="font-weight-bold py-6 accent lighten-5" style="min-height: 0 !important">
                                {{ $t(g.header) }}
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="py-5" style="">
                                <v-row :id="'gheader_' + g.order" v-if="GetShowHeader(g.order)">
                                    <v-col cols="12" :class="(GetColumnAttributes(col.name).hidden) ? 'mx-0 pa-0' : 'mx-0'"
                                           v-for="col in GetVisibleGroupColumns(g.order)"
                                           :key="col.name"
                                           :md="col.col != '' ? col.col : '6'">
                                        <field :state="GetFormState"
                                               :column="col"
                                               :attributes="GetColumnAttributes(col.name)"
                                               :invalue="GetField(col.name, col)"
                                               :doviz="GetField(col.doviz, col) || ''"
                                               @change="updateFormModel($event, col)"
                                               @changeGrid="UpdateExtDataModel($event, col)"
                                               @SearchInputChanged="SearchInputChanged($event, col)"
                                               @linkclicked="LinkClicked(col)"
                                               @buttonclick="ButtonClicked($event)"
                                               @FieldButtonClicked="FieldButtonClicked($event)"
                                               @changeAttribute="ChangeAttribute($event,col)"
                                               @changeProcessing="ChangeProcessing($event)"
                                               @RowButtonClicked="RowButtonClicked($event, col)"
                                               @HeaderButtonClicked="HeaderButtonClicked($event)">
                                        </field>
                                    </v-col>
                                    <v-col cols="2" v-if="GetDynoTabGroups(t.order).length-1 == index" class="text-start">
                                        <v-col cols="12" v-if="t.previousButtonId != undefined">
                                            <v-btn depressed block color="third" v-on:click="ChangeActiveTab(t.previousButtonId)">{{ $t('FL_PreviousPage') }}</v-btn>
                                        </v-col>
                                    </v-col>
                                    <v-col cols="8"></v-col>
                                    <v-col cols="2" v-if="GetDynoTabGroups(t.order).length-1 == index" class="text-end">
                                        <v-col cols="12" v-if="t.nextButtonId != undefined">
                                            <v-btn depressed block color="third" v-on:click="ChangeActiveTab(t.nextButtonId)">{{ $t('FL_NextPage') }}</v-btn>
                                        </v-col>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-tab-item>
            </v-tabs>
        </v-col>
    </v-row>
</template>

<script>
    import field from '@/components/Field.vue';
    import { mapGetters } from 'vuex';
    import Utilities from '@/Utilities.js'
    export default {
        name: 'dynoform',
        props: {
            header: String,
            enabled: Boolean,
            attributes: Object,
            tabname: String,
            durum: String,
            tableName: {
                type: String,
                default: function () {
                    return 'formdata'
                }
            },
            formInfo: Object,
        },

        data()
        {
            return {
                state: '',
                tab: ['Tab-1'], //burası açık tabları gösteriyor
                icons: false,
                centered: false,
                grow: false,
                vertical: false,
                prevIcon: false,
                nextIcon: false,
                right: false,
                showHeader: [],
                panel: Array(100 - 0 + 1).fill().map((_, idx) => 0 + idx)
            };
        },
        components: {
            field
        },

        computed: {
            ...mapGetters([
                'GetFormField',
                'GetFormState',
                'GetFormAttributes',
                'GetDynamicFormAttributes',
                'GetExtdataAttributes',
                'GetTabs',
                'GetVisableTabs',
                'GetGroupColumns',
                'GetTabGroups',
                'GetFormLoadingStatus'
            ]),

            GetShowHeader: function ()
            {
                var self = this;
                return g =>
                {
                    if (typeof self.showHeader[g] == 'undefined')
                    {
                        self.showHeader[g] = true;
                    }
                    return self.showHeader[g];
                };
            },
            AnyNotApprovedField: function ()
            {
                var columns = ['adres', 'anakategori', 'email', 'fax', 'firmaad', 'gsm', 'il', 'ilce', 'sahis', 'tel', 'verginum', 'yetkiliad', 'yurtici', 'ticaretodasifile', 'vergilevhasifile'];
                var attributes = this.GetAttributes();

                for (var i = 0; i < columns.length; i++)
                {
                    if (attributes[columns[i]] != undefined)
                    {
                        if (!attributes[columns[i]].approved)
                        {
                            return true;
                        }
                    }
                }

                return false;
            },
            GetColumnAttributes: function () {

                var self = this;

                var attributes = self.GetAttributes();
                return column => {
                    var result = {};
                    if (attributes && attributes[column]) result = attributes[column];

                    return result;
                };
            },
            GetDynoVisibleTabs: function () {
                if (this.IsFormData()) {
                    return this.GetVisableTabs;
                }
                else {
                    if (this.formInfo != undefined) {
                        var result = [];
                        var tabs = this.formInfo.tabs;
                        for (var i = 0; i < tabs.length; i++) {
                            var tab = tabs[i];
                            var order = tab.order;
                            if (this.formInfo.TabAttributes && this.formInfo.TabAttributes[order]) {
                                if (this.formInfo.TabAttributes[order].show) result.push(tab);
                            }
                            else {
                                result.push(tab);
                            }
                        }
                        return result;
                    }
                }
            },
            GetVisibleGroupColumns: function (tab)
            {
                var self = this;
                var attributes = self.GetAttributes();
                return tab =>
                {
                    var columns = [];

                    if (this.IsFormData()) {
                        columns = self.GetGroupColumns(tab);
                    }
                    else {
                        columns = _.filter(this.formInfo.Columns, function (o) {
                            return o.group == tab;
                        })
                        columns.sort((a, b) => { return a.sira - b.sira });
                    }

                    var visiblecolumns = _.filter(columns, function (o)
                    {
                        return o.group == tab && o.type != 'hidden';
                    });

                    // Bu column ların attribute larında hiden varsa onları da listeden çıkarart
                    var vlist = _.filter(visiblecolumns, function (c)
                    {
                        var attr = attributes[c];
                        if (attr && attr["hidden"]) return false;
                        return true;
                    })

                    return vlist;
                };
            },
            FormLoadingStatus: {
                get: function () {
                    Utilities.log('Get : '+this.GetFormLoadingStatus);
                    return this.IsFormData() ? this.GetFormLoadingStatus : false;
                }
            },
        },
        created: function ()
        {
            this.state = this.$store.getters.GetFormState;
        },
        mounted: function ()
        {
            Utilities.log('dynoform mounted Table:', this.tableName);
            this.tab = this.tabname;
        },

        methods: {

            updateFormModel: function (val, column)
            {
                //payload
                //  column ( colum definition) => fieldname
                //  value ( updated value)
                //  tableName (formdata = form_data)
                //  id table içinde id
                val.column = column;
                this.$emit('change', val);
                var payload = {};
                payload.tableName = this.tableName;
                payload.fieldName = column.name;
                payload.value = [];
                payload.selectedObject = val.selectedObject;
                this.$store.commit('SetFieldAttributes', payload);
            },
            UpdateExtDataModel: function (val, column) {
                this.$emit('changeGrid', val);
            },
            HeaderButtonClicked(event)
            {
                this.$emit('HeaderButtonClicked', event);
            },
            RowButtonClicked(event) {
                this.$emit('RowButtonClicked', event);
            },
            SearchInputChanged: function (val, column)
            {
                Utilities.log('Search in dyno ', val, column);
                var tableName = this.tableName;
                if (column.tableName != "") tableName = column.name;
                var id = 0;
                if (column.type == "grid") id = val.id;
                var payload = {
                    search: val.search,
                    fieldName: column.name,
                    tableName: tableName,
                    id: id,
                    column: column
                };
                this.$emit('SearchInputChanged', payload);
            },
            editable: function (f)
            {
                try
                {
                    return f.edit.indexOf(this.state) != -1 && this.enabled;
                } catch (e)
                {
                    return true;
                }
            },

            SetFormState: function (s)
            {
                var curstate = this.$store.getters.GetFormState;
                if (curstate == '3') s = '1';
                var val = { fieldName: 'state', fieldValue: s, tableName: 'formdata' };
                this.$store.commit('SetField', val); //.mutations.SetField(val);
            },

            handleAutoComplete: function (item)
            {
                this.$emit('autocomplete', item);
            },

            GetAttribs: function (fieldName)
            {
                var result = {};
                var attrib = this.attributes[fieldName];
                return attrib || {};
            },

            GetHidden: function (fieldName)
            {
                Utilities.log('FieldName GetHidden:' + fieldName);
                var result = '';
                var attrib = this.attributes || null;
                if (attrib) attrib = this.attributes[fieldName] || null;
                if (attrib) result = attrib.hide || '';
                var curstate = this.$store.getters.GetFormState;
                var hidden = result.indexOf(curstate) != -1;
                return hidden;
            },
            ButtonClicked: function (payload)
            {
                this.$emit('click', payload);
            },
            FieldButtonClicked: function (payload) {
                this.$emit('FieldButtonClicked', payload);
            },

            LinkClicked(payload)
            {
                this.$emit('linkclicked', payload);
            },

            ChangeProcessing: function (payload)
            {
                this.$emit("changeProcessing", payload);
            },
            ChangeAttribute: function (attribute, column)
            {
                var payload = { ...attribute, column };
                Utilities.log("Dynoform Emit changeAttribute", payload)
                this.$emit("changeAttribute", payload);
            },
            GetAttributes: function () {
                if (this.IsFormData()) {
                    return this.GetFormAttributes;
                }
                else if (this.formInfo.useStoreAttributes)
                {
                    return this.GetDynamicFormAttributes;
                }
                else {
                    return this.formInfo.attributes;
                }
            },
            IsFormData: function () {
                if (this.tableName == 'formdata') {
                    return true;
                }
                else {
                    return false;
                }
            },

            GetField: function (id, column) {
                return this.GetFormField(id, this.tableName);
            },

            GetDynoTabGroups: function (tab) {
                if (this.IsFormData()) {
                    return this.GetTabGroups(tab);
                }
                else {
                    if (this.formInfo != undefined) {
                        var grList = _.filter(this.formInfo.groups, function (o) {
                            return o.tab == tab;
                        });
                        return grList;
                    }
                }
            },

            ChangeActiveTab: function (tab) {
                this.tab = 'Tab-' + tab;
            },

        },
        watch: {
            FormLoadingStatus: {
                handler: function (val, oldVal) {
                    Utilities.log('FormLoadingStatus changed : ' + val);
                },
                deep: true
            },
            attributes: function (val, oldValue)
            {
                //Utilities.log("watch attributes in dynoforms");
            },
            showHeader: function (nval, oval)
            {
                Utilities.log("WATCH showHeader-- ", nval, oval);
            },
            tabname(newtab, oldtab)
            {
                Utilities.log("WATCH tabname", newtab)
                this.tab = newtab;
            },
            tab(newtab, oldtab)
            {
                Utilities.log("WATCH Change active tab", oldtab, newtab)
                //this.$emit('activetab', newtab);
            },
        }
    };
</script>
