
<template>
    <v-card flat outlined class="w-100"
            :class="{ gridError: Error, fieldDisabled: Disabled, fieldHidden: Hidden }"
            :error="Error">
        <v-card-title class="font-weight-bold subtitle-2 pa-2 ma-0">
            <div class="mr-12">
                {{ header }}
            </div>
            <v-spacer></v-spacer>
            <v-btn v-if="showExportToExcelButton" v-show="data.length"
                   outlined
                   rounded
                   text
                   depressed
                   color="secondary"
                   style="background-color: #E8E8E8;"
                   @click="GridToExcel">
                <v-icon left small>mdi-file-excel</v-icon>
                {{ $t("AL_ExportToExcel") }}
            </v-btn>
            <v-btn v-if="delrowlabel != ''"
                   outlined
                   rounded
                   text
                   depressed
                   @click="DelRow()"
                   style="        background-color: #fef1e9;"
                   class="ml-1">
                <v-icon left small>mdi-trash-can-outline</v-icon>
                {{ delrowlabel }}
            </v-btn>
            <v-btn v-if="addrow != ''"
                   outlined
                   rounded
                   text
                   depressed
                   @click="AddRow()"
                   class="ml-1"
                   color="primary">
                <v-icon left small>mdi-plus-circle</v-icon>
                {{ $t(addrow) }}
            </v-btn>

            <v-btn v-for="b in buttons" :key="b.text"
                   outlined
                   rounded
                   text
                   @click="HeaderButtonClicked(tableName + ':::' + b.text)"
                   :color="b.color"
                   class="ml-1">
                <v-icon left small>mdi-{{b.icon}}</v-icon>
                {{ b.text }}
            </v-btn>
        </v-card-title>

        <v-card-subtitle class="ma-0 pb-1 pl-2" v-if="addrow != ''">
            <p class="ma-0 pa-0">{{ $t("IM_AddRowToTable") }}</p>
        </v-card-subtitle>
        <v-data-table v-model="selected"
                      :footer-props="footerProps"
                      :headers="GetHeaders"
                      :items="data"
                      :search="searchkey"
                      :options.sync="options"
                      :loading="loading"
                      loading-text="$t('IM_LoadingText')"
                      :show-select="showselect"
                      item-key="id"
                      :group-by="GetGroupBy"
                      :show-group-by="GetGroupBy.length > 0"
                      :expanded="[]"
                      @item-expanded="Dump('Item-Expanded event', $event)"
                      :show-expand="false"
                      class="spaced"
                      :no-data-text="$t('IM_NoDataDisplay')">
            <template v-slot:top>
                <v-text-field v-model="searchkey" v-if="searchable"
                              placeholder="$t('AL_Search')"
                              tile
                              dense
                              hide-details
                              clearable
                              loading="0"
                              prepend-inner-icon="mdi-magnify"
                              color="accent darken-2"
                              class="ma-0 pa-2 rounded-0"></v-text-field>
            </template>
            <template v-slot:item="props">
                <tr class="w-100" :class="{rowactive: props.isSelected}" :id="'Row_' + props.index">
                    <td v-if="showselect">
                        {{Dump("Prop",props)}}
                        <v-checkbox v-model="props.isSelected" :ref="props.index"
                                    @click="SelectRow($event, props.item)"
                                    color=""
                                    class="ma-0 pa-0"
                                    hide-details></v-checkbox>
                    </td>
                    <td v-for="(col, i) in columns"
                        :key="i"
                        :style="GetCustomStyle(i)"
                        @click="RowClicked(props)"
                        v-on:dblclick="RowDooubleClicked(props)"
                        v-if="col.type != 'hidden' && !IsColumnHiddenByState(i)"
                        :id=" tableName + '_Row_' + props.index + '_' + i">

                        <gridfield :state="GetState"
                                   :column="getColDefinition(i)"
                                   :label="getColLabel(i)"
                                   :placeholder="getColLabel(i)"
                                   :invalue="getColDefinition(i).type == 'button'
                                            ? getColDefinition(i).label
                                            : props.item[i] "
                                   :row="props.index"
                                   :col="i"
                                   :type="getColType(i)"
                                   :attributes="GetColAttributes(i, props.item.rowid)"
                                   :tableName="tableName"
                                   @change="updateExtdataModel($event, i, props.item)"
                                   @click="ButtonClicked($event, props.item)"
                                   @SearchInputChanged="SearchInputChanged($event, col, props.item)"
                                   @changeAttribute="ChangeAttribute($event,props.item)"
                                   @rowupdown="RowUpDown($event,i,props)">
                        </gridfield>
                    </td>
                </tr>
            </template>
            <template v-slot:item.action="{ item }">
                <v-icon small class="mr-2" @click="ActionClicked(item, 'Edit')">
                    zoom_out_map
                </v-icon>
            </template>
            <template v-slot="loading">
                {{ $t("IM_Loading") }}

            </template>

        </v-data-table>
    </v-card>
</template>
<style>
    .v-data-table-header tr th {
        font-weight: 600 !important;
    }

    .rowactive {
        background-color: #fef1e9 !important;
    }

    .v-data-table.spaced > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table.spaced > .v-data-table__wrapper > table > tfoot > tr > td, .v-data-table.spaced > .v-data-table__wrapper > table > thead > tr > th {
        height: 60px;
    }

    .gridError {
        border: 2px solid red !important;
    }
</style>
<script>
    import gridfield from './GridField';
    import XLSX from 'xlsx';
    import Utilities from '@/Utilities.js'
    export default {
        name: 'grid',
        props: {
            data: {
                type: Array,
                default: function () { return [] }
            },
            maxLen: Number,
            columns: Object,
            header: String,
            tableName: { type: String, default: 'Table' },
            attributes: Object,
            addrow: { type: String, default: '' },
            loading: Boolean,
            groupby: String,
            searchable: { type: Boolean, default: false },
            buttons: Array,
            message: { type: Object, default: null },
            showselect: { type: Boolean, default: false },
            Error: { type: Boolean, default: false },
            Disabled: { type: Boolean, default: false },
            Hidden: { type: Boolean, default: false },
            rowupdown: { type: Boolean, default: false },
            delrow: { type: String, default: '' },
            showExportToExcelButton: { type: Boolean, default: true },
            itemsPerPageProp: { type: Number, default: 15 },
            HideHeader: { type: Boolean, default: false }
        },
        data()
        {
            return {
                options: {
                    //sortBy: ['rowid'],
                    page: 1,
                    itemsPerPage: 15,
                    rowsPerPageItems: [5, 15, 30, 50, 9999]
                },
                //footerProps: { 'items-per-page-options': [15, 30, 50, 100] },
                enabled: null,
                search: null,
                selected: [],
                headers: [],
                timestamp: 0,
                delrowlabel: '',
                searchkey: null,
                searchkeyInput: '',
                searchkeyInputTimer: 0,
                pagex: 1,
                pagey: 1,
                pageCountx: 0,
                itemsPerPagex: 10,
                pageCounty: 0,
                itemsPerPagey: 10,
                footerProps: {
                    'items-per-page-options': [5, 15, 30, 50, -1],
                    'show-first-last-page': true
                },
            };
        },
        components: {
            gridfield
        },

        computed: {

            GetState()
            {
                return this.$store.getters.GetFormState;
            },

            GetItems()
            {
                return this.data;
            },

            GetHeader(header)
            {
                Utilities.log('Grid Header:', header);
                if (
                    typeof header.label == 'undefined' &&
                    typeof header.text != 'undefined'
                )
                    return header.text;
                else if (typeof header.label != 'undefined') return header.label;
                return '';
            },
            GetHeaders()
            {
                Utilities.log("GetHeaders Columns**:", this.columns);
                var headers = [];
                if (this.HideHeader) {
                    return headers;
                }

                for (var key in this.columns)
                {
                    var column = this.columns[key];

                    if (column.type == 'hidden')
                    {
                        continue;
                    }

                    if (this.IsColumnHiddenByState(key))
                    {
                        continue;
                    }

                    headers.push({ text: this.$root.$i18n.t(column.label), value: key, width: column.width });
                }
                Utilities.log("Headers END:", headers);
                return headers;
            },
            GetColAttributes()
            {
                return (fieldName, id) =>
                {
                    try
                    {
                        const attributesOfColumn = this.$store.getters.GetExtdataColumnAttribute(this.tableName, fieldName)

                        if (attributesOfColumn)
                        {
                            return attributesOfColumn;
                        }
                    }
                    catch (e)
                    {
                        Utilities.log('GetColAttributes ex:', e);
                    }

                    return {};
                };
            },
            GetColumnAlignment()
            {
                return i =>
                {
                    var type = 'text';
                    var result = 'left';
                    var col = this.columns[i];
                    type = col.type;

                    if (type == 'money' || type == 'int') result = 'right';
                    else if (type == 'date') result = 'center';
                    return result;
                };
            },
            GetCustomStyle()
            {
                var count = Object.keys(this.columns).length;
                var counter = 0;
                return i =>
                {
                    var col = this.columns[i];
                    var result = 'width:' + col['width'] + '% !important; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; ';
                    counter++;
                    return result;
                };
            },
            GetGroupBy()
            {
                if (
                    typeof this.groupby == 'undefined' ||
                    this.groupby == null ||
                    this.groupby == ''
                )
                    return [];
                else return [this.groupby];
            },
            GetVal()
            {
                //col.type == 'button'? col.label : props.items[col.name]
                return (i, col, items) =>
                {
                    //Utilities.log('GetVal i:', i, ' Col:', col, 'Items:', items);
                    if (col.type == 'button') return col.label;
                    return items[0][col.name];
                };
            },
        },

        watch: {
            attributes: function (n, o)
            {
                Utilities.log(' Watch grid attributes new:', n, this.attributes);
            },
            message: function (nval, oval)
            {
                Utilities.log('New Message:', nval);
                if (nval.action == 'deselect')
                {
                    try
                    {
                        this.selected = [];
                    } catch (e)
                    {
                        Utilities.log('selected ex:', e);
                    }
                }
                if (nval.action == 'setselected')
                {
                    this.selected = nval.selected;
                }
                Utilities.log('seleted:', this.selected);
            },
            selected: function (nval, oval)
            {
                // SelectionChanged
                Utilities.log('SelectionChanged', nval);
                this.$emit('rowselected', nval);
            },
            pagex(nval, oval)
            {
                if (nval < 1)
                {
                    this.pagey = 1;
                    return;
                }
                if (nval > this.pageCountx) this.pagey = this.pageCountx;
                this.$emit('pagechanged', { page: nval, pageSize: this.itemsPerPage });
                //this.page = this.pagex;
            },
            itemsPerPagex(nval, oldval)
            {
                this.itemsPerPagey = Number(this.itemsPerPagex);
            },
            page(nval)
            {
                this.pagex = nval;
            },
            searchkeyInput(o, n)
            {
                if (this.searchable)
                {
                    clearTimeout(this.searchkeyInputTimer);
                    this.searchkeyInputTimer = setTimeout(this.SetGridSerch, 600);
                }
                else
                    this.searchkey = null;
            },
            data(n, o)
            {
                Utilities.log("Grid Data Watch ####################################", n);
                this.$emit('GridDataLoaded', { oldValue: o, newValue: n });
            }
        },
        mounted: function ()
        {
            Utilities.log('Grid mounted ------:', this.header, this.columns);
            this.pagex = this.page;
            this.options.itemsPerPage = this.itemsPerPageProp;
            this.itemsPerPagex = this.itemsPerPage;
        },


        beforeUpdate: function ()
        {
            Utilities.log("***GRID BEFOR UPDATE")
            this.$emit('gridbeforeupdated');
        },
        updated: function ()
        {
            if (this.data.length > 0)
            {
                if (this.selected.length == 0)
                {
                    this.delrowlabel = '';
                }
                else
                {
                    this.delrowlabel = this.$root.$i18n.t("AL_Delete") + ' (' + this.selected.length + ')';
                }
            }
            else
            {
                this.delrowlabel = '';
            }

            Utilities.log("***GRID UPDATED")
            this.$emit('gridupdated');
        },
        methods: {
            Dump(what, props)
            {
                Utilities.log('Dump Props of:', what, props);
            },

            IsColumnHiddenByState(fieldName)
            {
                const columnAttributes = this.GetColAttributes(fieldName);

                if (columnAttributes.hide &&
                    columnAttributes.hide.split('').includes(this.GetState))
                {
                    return true;
                }


                return false;
            },

            DumpItem(props)
            {
                for (var k in props.item)
                {
                }
            },

            isRowSelected: function (item)
            {
                this.selected.contains(item)
            },
            RowUpDown(event, i, props)
            {
                if (!this.rowupdown) return;
                Utilities.log("RowUpDown", event, i, props);
                var id = event.id;
                let match = id.match(/_input_(\d+)_/);
                if (match.length > 0)
                {
                    var delta = 1;
                    let row = parseInt(match[1]);
                    if (event.dir == 'up')
                    {
                        delta = -1;
                    }
                    var nrow = row + delta;

                    id = id.replace(row, nrow);
                    document.getElementById(id).focus();
                }

            },
            GridSearch(e, value)
            {
                Utilities.log('GridSearch', e, value);
            },

            ActionClicked(item, action)
            {
                this.$emit('actionclicked', { item, action });
            },
            SetGridSerch()
            {
                this.searchkey = this.searchkeyInput;
            },
            PerformSerach()
            {
                return;
            },
            SelectRow(e, row)
            {

                clearTimeout(this.timerId);
                var payload = {};
                payload.event = e;
                payload.data = row;
                this.timerId = setTimeout(this.SetSelected, 200, payload);
            },
            SetSelected(payload)
            {
                Utilities.log(
                    'Select row event at:',
                    new Date().getTime(),
                    ' e:',
                    payload.event,
                    ' row:',
                    payload.data
                );
                var row = payload.data;
                for (var i = 0; i < this.selected.length; i++)
                {
                    if (this.selected[i].rowid == row.rowid)
                    {
                        this.$emit('rowunchecked', row);
                        this.selected.splice(i, 1);
                        Utilities.log('Selected removed(rowunchecked):', this.selected);
                        return;
                    }
                }
                this.$emit('rowchecked', row);
                this.selected.push(row);

                Utilities.log('Selected added (rowchecked):', this.selected);
            },
            RowClicked(row)
            {
                Utilities.log('Grid row clicked row data:', row, new Date().getTime());
                this.$emit('rowclicked', row);
            },
            RowDooubleClicked(row)
            {
                Utilities.log("Grid row doubleclicked payload:", row);
                this.$emit('rowdoubleclicked', row);
            },


            getColDefinition(c)
            {
                var col = this.columns[c] || {};
                //Utilities.log("Col def:" + JSON.stringify(col));
                return col;
            },
            getColLabel(c)
            {
                var col = this.columns[c] || {};
                var label = col.label || '';
                //Utilities.log("res label:" + JSON.stringify(label));
                return label;
            },
            getColType(c)
            {
                var col = this.columns[c] || {};
                var type = col.type || '';
                // Utilities.log("res Type:" + JSON.stringify(type));
                return type;
            },
            updateExtdataModel(inpayload, columnName, rowdata)
            {
                var payload = {};
                payload.columnValue = inpayload;
                payload.columnName = columnName;
                payload.rowdata = rowdata;
                Utilities.log('Emit change in updateExtdataModel:', payload);
                this.$emit('change', payload);
            },

            ChangeAttribute: function (attribute, item)
            {
                var payload = { ...attribute, id: item.rowid };
                Utilities.log("Grid Emit changeAttribute", payload)
                this.$emit("changeAttribute", payload);
            },
            toggleAll()
            {
                if (this.selected.length) this.selected = [];
                else this.selected = this.GetItems.slice();
            },
            changeSort(column)
            {
                if (this.pagination.sortBy === column)
                {
                    this.pagination.descending = !this.pagination.descending;
                } else
                {
                    this.pagination.sortBy = column;
                    this.pagination.descending = false;
                }
            },
            AddRow()
            {
                this.$emit('AddRow', null);
            },
            DelRow()
            {
                var list = this.selected;

                for (var i = 0; i < list.length; i++)
                {
                    this.$store.commit('RemoveRowFromExtData', {
                        tableName: this.tableName,
                        rowid: list[i].rowid
                    });
                }
                this.message2 = { action: 'deselect', value: 0, random: Utilities.GetRandomNumber() };
                this.selected = [];
            },

            ButtonClicked(col, item)
            {
                //Col hangi butonun basıldığını, item hangi row a tıklandığını gösterir
                var payload = { button: col, item: item };
                this.$emit('RowButtonClicked', payload);
            },

            HeaderButtonClicked(button)
            {
                var payload = { button: button, list: this.selected };
                this.$emit('HeaderButtonClicked', payload);
            },
            SearchInputChanged: function (val, column, item)
            {
                Utilities.log('Search in dyno v :' + JSON.stringify(val));
                Utilities.log('Search in dyno c :' + JSON.stringify(column));
                var payload = {
                    search: val.search,
                    fieldName: column.name,
                    tableName: val.TableName,
                    id: item.rowid,
                    column: column,
                    item: item
                };
                Utilities.log('Search in emit:' + JSON.stringify(payload));
                this.$emit('SearchInputChanged', payload);
            },

            GridToExcel()
            {
                /* make the worksheet */
                var headerdata = [];
                for (var key in this.data[0])
                {
                    var currlabel = this.$root.$i18n.t(this.getColLabel(key));
                    let temp = this.getColType(key);
                    if (temp == 'hidden' || temp == '')
                        continue;
                    if (currlabel != '')
                        headerdata.push(currlabel);
                    else
                        headerdata.push(key);
                }
                var hh = [headerdata];
                var ws = XLSX.utils.aoa_to_sheet(hh);

                let clone = JSON.parse(JSON.stringify(this.data));
                for (var i = 0; i < clone.length; i++)
                {
                    for (var key in clone[i])
                    {
                        let temp = this.getColType(key);
                        if (temp == 'hidden' || temp == '')
                        {
                            delete clone[i][key];
                        }
                    }
                }

                XLSX.utils.sheet_add_json(ws, clone, { skipHeader: true, origin: "A2" });

                /* add to workbook */
                var wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

                /* write workbook */
                var date = new Date();
                var fileName = '' + date.getFullYear() + (date.getMonth() + 1).toString().padStart(2, '0') + date.getDate().toString().padStart(2, '0') + date.getHours() + date.getMinutes() + date.getSeconds() + date.getMilliseconds();
                XLSX.writeFile(wb, fileName + '.xlsx');
            },
            BindButtonsText(buttons)
            {
                var retval = '';
                var len = buttons.length;

                for (var i = 0; i < len; i++)
                {
                    retval += '"' + buttons[i].text + '"';

                    if (i < len - 1)
                    {
                        retval += (i < len - 2) ? ', ' : ' ve ';
                    }
                }

                return retval;
            }
        }
    };
</script>
