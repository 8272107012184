
import 'babel-polyfill';
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import VueMask from 'v-mask'
Vue.use(VueMask);


import App from './App.vue'
import router from './router'
import store from './store'

import { i18n, loadLanguageAsync } from '@/plugins/i18n'

import Vuetify from "vuetify"
import 'vuetify/dist/vuetify.min.css'

import '@mdi/font/css/materialdesignicons.css'
import "@/assets/style.css"

Vue.use(Vuetify);

const vuetifyOpts = {
    lang: {
        t: (key, ...params) => i18n.t(key, params)
    },

    theme: {
        dark: false,
        themes: {
            light: {
                primary: {
                    base: '#22366A'
                },
                success: '#28AD68',
                error: '#DA3951',
                info: '#2196F3',
                warning: '#F7893B',
                infoLight: '#50c3d1',
            },
        },
    }
}

Vue.config.productionTip = false
import Utilities from '@/Utilities.js'
import { FormMixIn } from "@/MixIns/FormMixins";
Vue.config.devtools = true;
new Vue({
    router,
    store,
    mixins: [FormMixIn],
    vuetify: new Vuetify(vuetifyOpts),
    i18n,
    methods:
    {
        KeepAlive()
        {
            return;
        },
        loadLanguageAsync(lang)
        {
            loadLanguageAsync(lang);
        }
    },
    mounted: function ()
    {
        Utilities.log("MAIN MOUNTED")
        var hostUrl =
            window.location.protocol +
            '//' +
            window.location.hostname +
            (window.location.port ? ':' + window.location.port : '') +
            '/';
        this.$store.commit('SetHostUrl', hostUrl);
        var self = this;
        // /Account/ k�sm� login i�lemleri i�in. User bilgisi daha yok

        var lang = Utilities.GetCurrentLanguage();
        this.loadLanguageAsync(lang);

        this.$store.dispatch("GetUserInfo")
            .then(response =>
            {
                if (self.$route.name != null && !this.IsAuthPage(self.$route.name))
                {
                    Utilities.log("GetUserInfo Response:" + response.data.errorMessage);
                    if (response.data.errorCode == 0)
                    {
                        if (response.data.result.Login == 0)
                        {
                            self.ShowDialog(this.$root.$i18n.t('EM_LoginExpired'), 'E', 'notification');
                            this.$router.push({ name: 'login' });
                        }
                        else
                        {
                            self.$store.commit("SetUserLoggedIn", response.data.result.Login == '1');
                            if (self.IsApprovedPage(this.$route.name) && !response.data.result.approved)
                            {
                                this.$router.push({ name: 'home' });
                            }
                        }
                    } else
                    {
                        self.ShowDialog(response.data.errorMessage, 'E', 'notification');
                        this.$router.push({ name: 'login' });
                    }
                }
            })
            .catch(err =>
            {
                console.log("GetUser Err:", err);
                if (err.data != undefined)
                {
                    self.ShowDialog(err.data.errorMessage, 'E', 'notification');
                    if (err.data.errorCode == 401)
                    {
                        this.$router.push({ name: 'login' });
                    }
                }
            });

    },
    render: h => h(App)
}).$mount('#app')

