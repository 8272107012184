<template>
    <div>
        <v-container fluid class="pa-0">
            <v-card tile flat outlined class="w-100" v-if="loading">
                <v-card-title class="font-weight-bold subtitle-2 pa-2 ma-0" style="display:block">
                    <v-skeleton-loader class="pa-2"
                                       max-height="30"
                                       max-width="150"
                                       type="image"></v-skeleton-loader>
                </v-card-title>
                <v-skeleton-loader class="mx-auto"
                                   type="table-thead" :types="{'table-thead': 'heading@4'}"></v-skeleton-loader>
                <v-skeleton-loader class="mx-auto"
                                   type="table-tbody" :types="{'table-tbody': 'table-row-divider@15','table-row': 'table-cell@5'}"></v-skeleton-loader>
                <v-skeleton-loader class="ml-auto"
                                   max-width="500"
                                   type="table-tfoot" :types="{ 'table-tfoot': 'text@1, avatar@1, text@2, avatar@4'}"></v-skeleton-loader>

            </v-card>
            <grid v-else
                  :columns="GetViewColumns"
                  :data="GetViewData"
                  :header="ViewTitle"
                  @rowclicked="RowClicked($event)"
                  @pagechanged="PageChanged($event)"
                  :attributes="{}"
                  :loading="GetViewLoading"
                  :addrow="''"
                  dense="true"
                  :page="page"
                  :itemsperpage="itemsperpage"
                  :clickrow="1 == 1"
                  :showselect="false" />
        </v-container>
        <v-container fluid>
            <form>
                <field v-for="(s, index) in GetSearchKeys"
                       :key="s.fieldname"
                       :column="{
                        type: 'text',
                        name: s.fieldname,
                        label: s.label,
                        edit: '0'
                    }"
                       state="0"
                       ::attributes="{}"
                       @change="updateViewModel($event, index)"
                       :invalue="GetKeyValue(index)"
                       @SearchInputChanged="SearchInputChanged($event, col)">
                </field>
                <v-btn primary @click="Search">{{$t("AL_Search")}}</v-btn>
            </form>
        </v-container>
    </div>
</template>
<script>
    import { mapGetters } from 'vuex';
    import grid from '@/components/grid.vue';
    import field from '@/components/Field.vue';
    import Utilities from '@/Utilities.js'

    export default {
        name: 'kview',
        props: {
            form: String,
            viewname: String,
            extCondition: String,
        },
        components: {
            grid,
            field
        },
        data() {
            return {
                header: 'View Header',
                columns: [],
                data: [],
                viewactions: [],
                name: 'sedat',
                keyvalues: ['', '', '', '', '', '', '', ''],
                k0: '',
                k1: '',
                k2: '',
                k3: '',
                k4: '',
                k5: '',
                k6: '',
                k7: '',
                k8: '',
                k9: '',
                gridInfo: {},
                page: 1,
                itemsperpage: 10,
                loading: true
            };
        },
        computed: {
            ...mapGetters([
                'GetViewColumns',
                'GetViewData',
                'GetViewTitle',
                'GetSearchKeys',
                'GetViewLoading',
                'GetViewPagingInfo',
                'GetFormField',
                'GetViewFilter',
                'GetWhatToShow',
            ]),
            GetKeyValue: function () {
                return (index) => {
                    switch (index) {
                        case 0: return this.k0; //break;
                        case 1: return this.k1; //break;
                        case 2: return this.k2; //break;
                        case 3: return this.k3; //break;
                        case 4: return this.k4; //break;
                        case 5: return this.k5; //break;
                        case 6: return this.k6; //break;
                        case 7: return this.k7; //break;
                        case 8: return this.k8; //break;
                        case 9: return this.k9; //break;
                    }

                }
            },
            WhatToShow: function () {
                return this.GetWhatToShow;
            },
            ViewTitle: function () {
                return this.$root.$i18n.t(this.GetViewTitle);
            }
        },
        mounted() {
            Utilities.log('View Mounted', this.form);
            this.page = 1;
            this.itemsperpage = 10;
            if (this.viewname == "") return;
            this.Search();
        },
        methods: {
            LoadView() {
                Utilities.log(" Load view whattoshow", this.GetWhatToShow);
                if (typeof this.viewname == 'undefined' || this.viewname == '' || this.GetWhatToShow == "form") return;
                var payload = {};
                payload.form = this.form;
                payload.viewName = this.viewname;
                payload.viewfilter = this.GetViewFilter;

                payload.parameters = [];

                if (this.extCondition != undefined && this.extCondition !== '') {
                    this.extCondition.split(';').map(item => {
                        payload.parameters.push({
                            fieldname: item.split('=')[0],
                            fieldValue: item.split('=')[1]
                        })
                    });
                }

                var self = this;
                payload.success = function (response) {
                    Utilities.log('mount viewname success:' + response);
                    if (response.data.errorCode == 0) {
                        Utilities.log('payload.success watch GetView:', response);
                        self.$store.commit('SetView', response.data);
                    } else {
                        var messages = {
                            errors: [response.data.errorMessage],
                            infoBody: '',
                            messageType: 'E',
                            viewType: 'notification',
                        };
                        this.$root.$emit('setUpDialog', messages);
                        if (response.data.errorCode == 401) {
                            this.$router.push({ name: 'login' });
                        }
                    }
                    self.loading = false;
                };
                payload.error = function (error) {
                    Utilities.log('View getview watch hata', error);
                    self.loading = false;
                };
                self.loading = true;
                this.$store.dispatch('GetView', payload);
                self.$store.commit('SetWhatToShow', { show: 'view' });
            },
            RowClicked: function (row) {
                this.$emit('rowclicked', { row: row, view: this.viewname });
            },
            Search: function () {
                Utilities.log("search in view")
                var whattoshow = this.GetWhatToShow;
                Utilities.log("View Search whatto show:", whattoshow)
                if (whattoshow == "form") return;

                var payload = {};
                payload.form = this.form;
                payload.viewName = this.viewname;
                payload.extCondition = this.extCondition;
                payload.parameters = [];
                payload.viewfilter = this.GetViewFilter;

                if (this.extCondition != undefined && this.extCondition !== '') {

                    this.extCondition.split(';').map(item => {
                        payload.parameters.push({
                            fieldname: item.split('=')[0],
                            fieldValue: item.split('=')[1]
                        })
                    });
                }

                var searchKeys = this.GetSearchKeys;
                Utilities.log("View Search Keys:", searchKeys)
                var self = this;
                if (typeof searchKeys != "undefined" && searchKeys != null) {
                    for (var ix = 0; ix < searchKeys.length; ix++) {
                        var key = searchKeys[ix];
                        var parameter = {
                            fieldname: key.fieldname,
                            fieldValue: this.GetKeyValue(ix)
                        };
                        payload.parameters.push(parameter);
                    }
                }
                payload.success = response => {

                    Utilities.log("view search payload response", self.viewname, whattoshow);
                    if (response.data.errorCode == 0) {
                        this.$store.commit('SetView', response.data);
                    } else {
                        var messages = {
                            errors: [response.data.errorMessage],
                            infoBody: '',
                            messageType: 'E',
                            viewType: 'notification',
                        };
                        this.$root.$emit('setUpDialog', messages);
                        if (response.data.errorCode == 401) {
                            this.$router.push({ name: 'login' });
                        }
                    }
                    this.loading = false;
                };
                payload.error = error => {
                    Utilities.log('View getview watch hata', error);
                    this.loading = false;
                };
                this.$store.dispatch('GetView', payload);
                this.$store.commit('SetWhatToShow', { show: 'view' });
            },
            updateViewModel: function (event, index) {

                switch (index) {
                    case 0: this.k0 = event.fieldValue; break;
                    case 1: this.k1 = event.fieldValue; break;
                    case 2: this.k2 = event.fieldValue; break;
                    case 3: this.k3 = event.fieldValue; break;
                    case 4: this.k4 = event.fieldValue; break;
                    case 5: this.k5 = event.fieldValue; break;
                    case 6: this.k6 = event.fieldValue; break;
                    case 7: this.k7 = event.fieldValue; break;
                    case 8: this.k8 = event.fieldValue; break;
                    case 9: this.k9 = event.fieldValue; break;
                }
                return;
                //var searchKeys = this.GetSearchKeys;
                //var index = searchKeys.findIndex(function(o) {
                //	var result = o.fieldname == fieldName;
                //	return result;
                //});
                //if (index != -1) {
                //	this.keyvalues[index] = event.fieldValue;
                //}
            },
            PageChanged(payload) {
                this.$store.commit('SetViewPagingInfo', payload);
            }
        },
        watch: {
            viewname: function (nval, oval) {
                Utilities.log('watch viewnamein view old:' + oval + ' new:' + nval);
                if (nval == "") return;
                this.Search();
            },
            WhatToShow: function (nval, oval) {
                Utilities.log('watch WhatToShow show old:' + oval + ' new:' + nval);
                if (nval == "") return;
                this.Search();
            }
        }
    };
</script>
